import "moment/locale/it";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/pt";

import classnames from "classnames";
import Dropdown from "components/Dropdown";
import ArrowHeading from "components/Icons/Arrowheading";
import Filter from "components/Icons/Filter";
import consts from "consts/consts";
import {
  addDays,
  isSameDay,
  setHours,
  setMinutes,
  setSeconds,
  subDays,
} from "date-fns";
import { getFromLocal } from "helpers/localStorage";
import { renameObjectKey } from "helpers/utility";
import moment from "moment";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import style from "./style.module.css";

window.__locatedId__ = getFromLocal("languageId");

export const SelectType = {
  DATE: "DATE",
  DAYPARTS: "DAYPARTS",
  HOURS: "HOURS",
};

const sortArray = [
  "N° ascending",
  "N° descending",
  "Name ascending",
  "Name descending",
  "Favorites first",
];

class EpgHeaderSelector extends Component {
  findChannelRef = React.createRef();
  state = {
    dateOptions: this.getOptions(SelectType.DATE),
    dayPartsOptions: this.getOptions(SelectType.DAYPARTS),
    hoursOptions: this.getOptions(SelectType.HOURS),
    dateValue: this.getOptions(SelectType.DATE)[this.defaultDateValue],
    dayPartsValue: this.getOptions(SelectType.DAYPARTS)[0],
    hoursValue: this.getOptions(SelectType.HOURS)[new Date().getHours()],
    sortedGroupsFormated: [],
  };

  constructor(args) {
    super(args);
    this.defaultDateValue = 7;
    this.containerDate = React.createRef();
    this.containerHours = React.createRef();
    //this.containerFindChannel = React.createRef();
    moment.updateLocale("FR", {
      monthsShort: moment.monthsShort("-MMM-"),
    });
    moment.updateLocale("EN", {
      monthsShort: moment.monthsShort("-MMM-"),
    });
  }

  /* WARNING : Dirty hack
   * Preserve the epg filter if there values exists in the url */
  componentDidMount() {
    const { dateOptions, hoursOptions } = this.state;
    const { groups, t } = this.props;
    const search = new URLSearchParams(window.location.search);
    let sortedGroupsFormated = [];
    if (groups?.length > 0) {
      sortedGroupsFormated = groups.slice();
      sortedGroupsFormated.map((obj) => {
        renameObjectKey(obj, "count", "value");
        renameObjectKey(obj, "group", "label");
        return obj;
      });
      let dump = sortArray.map((obj) => {
        return { label: obj };
      });
      sortedGroupsFormated.unshift({ label: t("Sort by"), subData: dump });
    }
    const date = search.get("date"),
      filter = search.get("filter"),
      hours = search.get("hours"),
      sort = search.get("sort");
    if (hours) {
      const findHours = hoursOptions.find((hourOption) => {
        return hourOption.label === hours;
      });
      if (findHours) {
        this.onChange(
          { label: findHours.label, val: findHours.value },
          "hours"
        );
      }
    }
    if (date) {
      const findDate = dateOptions.find((dateOption) => {
        return dateOption.label === date;
      });
      if (findDate) {
        let dateValue = new Date(findDate.value);
        if (hours) {
          dateValue = setHours(dateValue, hours.split(":")[0]);
        }
        this.onChange({ label: findDate.label, val: dateValue }, "date");
      }
    }
    if (sort || filter) {
      for (const groupObj of sortedGroupsFormated) {
        if (groupObj.subData && sort) {
          for (const sortObj of groupObj.subData) {
            if (sortObj.label === sort) {
              this.onChange(sortObj.label, "sort");
              break;
            }
          }
        } else if (filter === groupObj.label) {
          this.onChange(groupObj, "filter");
        }
      }
    }
    this.setState({
      sortedGroupsFormated,
      searchSort: sort,
      searchFilter: filter,
    });
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (this.props.refDate !== prevProps.refDate) {
      this.setState({
        dateOptions: this.getOptions(SelectType.DATE),
        dayPartsOptions: this.getOptions(SelectType.DAYPARTS),
        hoursOptions: this.getOptions(SelectType.HOURS),
        dateValue: this.getOptions(SelectType.DATE)[this.defaultDateValue],
        dayPartsValue: this.getOptions(SelectType.DAYPARTS)[0],
        hoursValue: this.getOptions(SelectType.HOURS)[
          new Date(this.props.refDate).getHours()
        ],
      });
    }
  }

  onChange(data, field) {
    const { onChange, handleApplyFilter, handleApplySort, history, t } =
      this.props;
    if (field === "sort") {
      handleApplySort(data);
    } else {
      this.setState({ [field + "Value"]: data });
      if (onChange) {
        onChange(data.val || data.value);
      }
      if (handleApplyFilter && !["date", "dayParts", "hours"].includes(field)) {
        handleApplyFilter(data);
      }
    }
    if (["date", "filter", "hours", "sort"].includes(field)) {
      const search = new URLSearchParams(window.location.search);
      search.set(field, field === "sort" ? data : data.label);
      history.push({
        search: "?" + search.toString(),
      });
    } else if (field === "dayParts") {
      const search = new URLSearchParams(window.location.search);
      search.set("date", t("Today"));
      search.set("hours", new Date(data.value).getHours() + ":00");
      history.push({
        search: "?" + search.toString(),
      });
    }
  }

  onClick(value, field) {
    this.setState({ [field + "Value"]: value });

    const { onChange } = this.props;
    if (onChange) {
      onChange(value.value);
    }
  }

  handleLeftArrow() {
    const { handleLeftArrow } = this.props;
    if (handleLeftArrow) {
      handleLeftArrow();
    }
  }

  handleRightArrow() {
    const { handleRightArrow } = this.props;
    if (handleRightArrow) {
      handleRightArrow();
    }
  }
  getDateLabel(date) {
    let lng = consts.languageIdMapping[getFromLocal("languageId")];
    moment.updateLocale(lng == "ar" ? "ar-MA" : lng, {});
    const dateFormat = moment(date).format(consts.epgDateFormat);
    const dateEpg =
      consts.languageId !== "eng"
        ? dateFormat.toString().replace(".", "")
        : dateFormat;
    return dateEpg;
  }
  getOptions(type) {
    const { t } = this.props;

    const options = [];
    const now = new Date();
    const refDate = new Date(this.props.refDate);

    switch (type) {
      case SelectType.DATE:
        // eslint-disable-next-line no-case-declarations
        let selectedOptionPosition = 0;
        if (refDate) {
          for (let count = consts.pastNumberDaysEPG; count > 0; count--) {
            const date = subDays(new Date(), count);
            const value = setHours(date, refDate.getHours());

            const dateEpg = this.getDateLabel(value);

            const label = count === 1 ? t("Yesterday") : dateEpg;
            if (isSameDay(refDate, value)) {
              this.defaultDateValue = selectedOptionPosition;
            }
            selectedOptionPosition += 1;
            options.push({
              label: label,
              value: value,
            });
          }
          if (isSameDay(refDate, now)) {
            this.defaultDateValue = selectedOptionPosition;
          }
          selectedOptionPosition += 1;
          options.push({
            label: t("Today"),
            value: setHours(now, refDate.getHours()),
          });
          for (let count = 1; count <= consts.futurNumberDaysEPG; count++) {
            const date = addDays(new Date(), count);
            const value = setHours(date, refDate.getHours());
            const dateEpg = this.getDateLabel(value);
            const label = count === 1 ? t("Tomorrow") : dateEpg;
            if (isSameDay(refDate, value)) {
              this.defaultDateValue = selectedOptionPosition;
            }
            selectedOptionPosition += 1;
            options.push({
              label: label,
              value: value,
            });
          }
        }
        break;
      case SelectType.DAYPARTS:
        options.push({ label: t("Now"), value: now });
        // eslint-disable-next-line no-case-declarations
        let epgTonightDate = new Date();
        // eslint-disable-next-line no-case-declarations
        let epgTonightHour = 20;
        // eslint-disable-next-line no-case-declarations
        let epgTonightMinute = 0;

        if (consts.epgTonightHour) {
          let epgTonightHourMinute = consts.epgTonightHour.split(":");

          if (epgTonightHourMinute[0]) {
            epgTonightDate = setHours(epgTonightDate, epgTonightHourMinute[0]);
          } else {
            epgTonightDate = setHours(epgTonightDate, epgTonightHour);
          }
          if (epgTonightHourMinute[1]) {
            epgTonightDate = setMinutes(
              epgTonightDate,
              epgTonightHourMinute[1]
            );
          } else {
            epgTonightDate = setMinutes(epgTonightDate, epgTonightMinute);
          }
        } else {
          epgTonightDate = setHours(epgTonightDate, epgTonightHour);
          epgTonightDate = setMinutes(epgTonightDate, epgTonightMinute);
        }
        epgTonightDate = setSeconds(epgTonightDate, 0);

        options.push({ label: t("Tonight"), value: epgTonightDate });
        break;
      case SelectType.HOURS:
        for (let hours = 0; hours < 24; hours++) {
          if (hours < 10) {
            options.push({
              label: `0${hours}:00`,
              value: setHours(refDate, hours),
            });
          } else {
            options.push({
              label: `${hours}:00`,
              value: setHours(refDate, hours),
            });
          }
        }
        break;
      default:
        break;
    }
    return options;
  }
  render() {
    const { rootClassName, t } = this.props;
    const {
      dateOptions,
      dateValue,
      dayPartsOptions,
      dayPartsValue,
      hoursOptions,
      hoursValue,
      sortedGroupsFormated,
    } = this.state;

    return (
      <div className={classnames(rootClassName, style.headerEpg)}>
        <div className={style.leftContainer}>
          <Dropdown
            selfClosing={true}
            label={t(dateValue.label)}
            dropdownIcon={true}
            handleClick={(data) => this.onChange(data, "date")}
            data={dateOptions.map((elm) => {
              return { label: elm.label, val: elm.value };
            })}
            stayOpenOnSelect={true}
          />
          <Dropdown
            selfClosing={true}
            label={hoursValue.label}
            dropdownIcon={true}
            handleClick={(data) => this.onChange(data, "hours")}
            data={hoursOptions.map((elm) => {
              return { label: elm.label, val: elm.value };
            })}
            stayOpenOnSelect={true}
          />
          <div className={style.pipeIcon} />
          {dayPartsOptions &&
            dayPartsValue &&
            dayPartsOptions.map((value, index) => {
              return (
                <span
                  key={index}
                  className={style.link}
                  onClick={this.onChange.bind(this, value, "dayParts")}
                >
                  <Trans>{value.label}</Trans>
                </span>
              );
            })}
        </div>
        <div className={style.rightContainer}>
          <div className={style.containerDropdownFilter}>
            <Dropdown
              iconOnClick={<Filter className={classnames(style.filter)} />}
              title={"EPG"}
              selfClosing={true}
              handleClick={(data) => this.onChange(data, "filter")}
              data={sortedGroupsFormated}
              stayOpenOnSelect={true}
              subDataHandleClick={(data) => this.onChange(data, "sort")}
              searchSort={this.state.searchSort}
              searchFilter={this.state.searchFilter}
            />
          </div>
          <div
            className={style.containerIcon}
            onClick={this.handleLeftArrow.bind(this)}
          >
            <ArrowHeading className={classnames(style.leftArrow)} />
          </div>
          <div
            className={style.containerIcon}
            onClick={this.handleRightArrow.bind(this)}
          >
            <ArrowHeading className={classnames(style.rightArrow)} />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(EpgHeaderSelector));
