import { LogoTypes } from "components/LogoNav";
import consts from "consts/consts";
import { TucanoModels } from "web-api/main";

import routes from "../consts/routes";
import * as tucanoConsts from "../consts/tucano";
import { buildQueryString } from "./urlHelper";

export function generateImageUrl(item, options = {}) {
  //console.log("item option => ", options);
  // Only HomeContent require to add baseId for objectId
  let objectId = item.getId();
  let imageType;
  // Homecontent
  if (item.assetType?.toLowerCase() === "episode") {
    objectId = item?.tags?.imageId || item.id;
    imageType = item?.tags?.imageId ? "image" : tucanoConsts.ImageType.VOD_THUMBNAIL;
  } else if (item.assetType?.toLowerCase() === "series") {
    objectId = item.parentId || item.id;
    imageType = tucanoConsts.ImageType.VOD_CATEGORY_BACKGROUND;
  } else if (item.idType === 4 && item.type !== tucanoConsts.ImageType.VOD_BACKGROUND) {
    objectId = item?.tags?.imageId || item.id;
    imageType = item?.tags?.imageId
      ? "image"
      : typeof item.isCatchup === "function" && item.isCatchup()
        ? tucanoConsts.ImageType.CATCHUP_BACKGROUND
        : tucanoConsts.ImageType.VOD_THUMBNAIL;
  } else {
    if (item.baseId && item.baseId !== 0) {
      if (item.getParentId && item.getParentId() && !item.isTVShowEpisode()) {
        objectId = `${item.baseId}_` + item.getParentId();
      } else {
        objectId = `${item.baseId}_${item.id}`;
      }
    }
    if (item.tags && item.tags.imageId) {
      objectId = item.tags.imageId;
    }
    // EpgEvent in Homepage
    if (item.type === "EPGBroadcast") {
      objectId = item.getImageId();
    }
    if (item instanceof TucanoModels.Channel) {
      objectId = item.id;
      if (item.imageId) {
        objectId = item.imageId;
      }

      if (item.type) imageType = item.type;
      else imageType = 100;
    }
    // TVShow
    if (!item.baseId && item.getParentId && item.getParentId() && !item.isTVShowEpisode()) {
      objectId = item.getParentId();
    }

    if (item && item.baseId && item.isAssetTVShowEpisode) {
      imageType = item.type;
    }

    if ((item.tags && item.tags.imageId) || item.imageId) {
      imageType = "image";
    }

    if (item && item.type === tucanoConsts.ImageType.VOD_THUMBNAIL && item.getCatchupId()) {
      imageType = tucanoConsts.ImageType.CATCHUP_THUMB;
    }

    if (item && item.type === tucanoConsts.ImageType.VOD_CATEGORY_BACKGROUND && item.isTVShowEpisode()) {
      imageType = tucanoConsts.ImageType.VOD_BACKGROUND;
    }

    if (item && item.type === tucanoConsts.ImageType.VOD_THUMBNAIL && item.isTVShow() && !item.isTVShowEpisode()) {
      imageType = tucanoConsts.ImageType.VOD_CATEGORY_THUMBNAIL;
    }

    if (item && item.type === tucanoConsts.ImageType.VOD_THUMBNAIL && item.isTVShow() && item.isTVShowEpisode()) {
      imageType = tucanoConsts.ImageType.VOD_THUMBNAIL;
    }

    if (item && item.type === "EPGBroadcast") {
      imageType = 500;
    }
    if (item instanceof TucanoModels.Asset && item.providerId && !item.isTVShow()) {
      objectId = item.id;
      imageType = item.type;
      if (item.getCatchupId()) imageType = 511;
    }

    if (item.tags && item.tags.imageId) {
      imageType = "image";
      objectId = item.tags?.imageId;
    }

    if (options.usePosterImage && item.type !== "link") {
      objectId = item.id;
      // imageType = tucanoConsts.ImageType.VOD_CATEGORY_BACKGROUND;
      imageType =
        item.type === "channel_logo"
          ? "channel_logo"
          : item.idType === 4 &&
            item.type === tucanoConsts.ImageType.VOD_BACKGROUND &&
            item.assetType?.toLowerCase() === "movie"
            ? tucanoConsts.ImageType.VOD_BACKGROUND
            : item.type === tucanoConsts.ImageType.VOD_BACKGROUND ||
            item.type === tucanoConsts.ImageType.CATCHUP_THUMB ||
            item.type === tucanoConsts.ImageType.VOD_CATEGORY_THUMBNAIL
              ? item.type
              : tucanoConsts.ImageType.VOD_CATEGORY_BACKGROUND;
    }
  }
  const params = {
    type: imageType || item.type,
    objectId: objectId || item.id,
    format_w: options.width || 1920,
    format_h: options.height || 1080,
    languageId: options.languageId || "eng",
  };

  if (options.width) {
    params.format_w = options.width || 1920;
  }

  if (options.height) {
    params.format_h = options.height || 1080;
  }
  return `${routes.imgdata}?${buildQueryString(params)}`;
}

export function generatePosterImageUrl(item, options = {}) {
  // vod movies = type 512
  // vod series = type 550
  let objectId = item.id;
  let imageType;
  // Only epgEvent in HomeContent need different objectId
  if (item.type === "EPGBroadcast") {
    objectId = item.getImageId();
  }
  if (item.type === "EPGBroadcast") {
    imageType = 500;
  }
  if (item.type === tucanoConsts.ImageType.VOD_THUMBNAIL) {
    imageType = tucanoConsts.ImageType.VOD_BACKGROUND;
  }
  if (
    item.type === tucanoConsts.ImageType.VOD_CATEGORY_THUMBNAIL ||
    (item.type === tucanoConsts.ImageType.VOD_BACKGROUND && item.idType !== 4)
  ) {
    imageType = tucanoConsts.ImageType.VOD_CATEGORY_BACKGROUND;
  }
  if (item.tags && item.tags.imageId) {
    objectId = item.tags.imageId;
    imageType = "image";
  }
  if (!item.type) {
    imageType = "profile";
    objectId = item.getImageId();
  }

  if (typeof item.isCatchup === "function" && item.isCatchup()) {
    imageType = tucanoConsts.ImageType.CATCHUP_THUMB;
  }
  if (item.isCatchupAsset && item.type !== tucanoConsts.ImageType.VOD_THUMBNAIL) {
    imageType = tucanoConsts.ImageType.CATCHUP_THUMB;
  }

  const params = {
    type: imageType || item.type,
    objectId: objectId,
    format_w: options.width || 1920,
    format_h: options.height || 1080,
    languageId: options.languageId || "eng",
  };

  if (options.width) {
    params.format_w = options.width || 1920;
  }

  if (options.height) {
    params.format_h = options.height || 1080;
  }

  return `${routes.imgdata}?${buildQueryString(params)}`;
}

export const generateFlyImage = (type) => {
  return `${consts.image_hostname}${routes.imgdata}?type=global&objectId=fly_${type}&format_w=40&format_h=19`;
};

export const generateImageWithNewConfig = (defaultImage, typeImage) => {
  if (Math.round(consts?.versionBackend) >= 4) {
    if (typeImage === "favicon") {
      return consts?.logos?.favicon
        ? `${consts.graphBaseUrl}/configuration/${consts.apiKey}/data/${consts?.logos?.favicon}`
        : defaultImage;
    } else if (typeImage == LogoTypes.RECTANGLE) {
      return consts?.logos?.rectangle
        ? `${consts.graphBaseUrl}/configuration/${consts.apiKey}/data/${consts?.logos?.rectangle}`
        : defaultImage;
    } else {
      return consts?.logos?.square
        ? `${consts.graphBaseUrl}/configuration/${consts.apiKey}/data/${consts?.logos?.square}`
        : defaultImage;
    }
  } else {
    return defaultImage;
  }
};
