import classnames from "classnames";
import EyeClosed from "components/Icons/EyeClosed";
import EyeOpened from "components/Icons/EyeOpened";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import MaskedInput from "react-text-mask";

import style from "./style.module.css";

// const autoCorrectedDatePipe = createAutoCorrectedDatePipe(consts.dateFormat);

class Input extends Component {
  state = {
    value: "",
    displayPassword: true,
    patern: "",
    showErrorMessage: false,
    error: [],
  };

  componentDidMount() {
    const { pattern } = this.props;
    if (this.props && this.props.value) {
      let val = this.props.value;
      if (pattern && pattern === "DATEOFBIRTH") {
        val = this.props.value;
      }

      this.setState((prevState) => ({
        ...prevState,
        value: val || "",
      }));
    }
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    const { pattern, t } = this.props;

    let dateFormat = t("dateFormat");
    if (dateFormat === "dateFormat") {
      dateFormat = consts.dateFormat;
    }
    if (prevProps !== this.props && this.props.value !== this.state.value) {
      let val = this.props.value;

      if (pattern && pattern === "DATEOFBIRTH") {
        val =
          this.props.value && this.props.value != "0000-00-00"
            ? moment(this.props.value, dateFormat.toUpperCase()).format(
                dateFormat.toUpperCase()
              )
            : "";
      }
      this.setState((prevState) => ({
        ...prevState,
        value: val || "",
      }));
    }

    if (prevProps.error !== this.props.error) {
      let error = [];
      let showErrorMessage = false;

      if (this.props.error) {
        error.push("Required");
        showErrorMessage = true;
      }

      this.setState((prevState) => ({
        ...prevState,
        error: error,
        showErrorMessage: showErrorMessage,
      }));
    }
  }

  getPattern(pattern) {
    let inputPattern = "";
    switch (pattern) {
      case "DATEOFBIRTH":
        inputPattern = this.getPaternFromConstDateFormat();
        break;
      default:
        break;
    }

    return inputPattern;
  }

  handleChange(event) {
    const { required, pattern, t } = this.props;
    const value = event.target.value;
    let dateFormat = t("dateFormat");
    if (dateFormat === "dateFormat") {
      dateFormat = consts.dateFormat;
    }
    let error = [];

    if (required && value === "") {
      error.push(t("Required"));
    } else {
      if (
        (pattern &&
          this.getPattern(pattern) &&
          !this.getPattern(pattern).test(value)) ||
        !moment(value, dateFormat.toUpperCase()).isValid()
      ) {
        error.push(t("Wrong format"));
      } else {
        if (pattern === "DATEOFBIRTH") {
          if (moment(value, dateFormat.toUpperCase()) > moment()) {
            error.push(t("The date must not be in the future"));
          } else {
            if (
              moment(value, dateFormat.toUpperCase()) >
              moment().subtract(18, "years")
            ) {
              error.push(t("You must be 18 years old"));
            }
          }
        }
      }
    }

    this.setState({ error: error });
    if (this.props.onChange) {
      this.props.onChange(value);
    }

    this.setState({ value: value, showErrorMessage: true });
  }

  handleFocus() {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  togglePasswordField() {
    const newValue = !this.state.displayPassword;
    this.setState({ displayPassword: newValue });
  }

  getMaskFromConstDateFormat() {
    let mask = [];
    let dateFormat = this.props.t("dateFormat");
    if (dateFormat === "dateFormat") {
      dateFormat = consts.dateFormat;
    }
    if (dateFormat) {
      let tmp = dateFormat.split("");
      if (tmp && tmp.length > 0) {
        tmp.forEach(function (char) {
          if (char === "y" || char === "m" || char === "d") {
            mask.push(/\d/);
          } else {
            mask.push(char);
          }
        });
      }
    }
    return mask;
  }

  getPaternFromConstDateFormat() {
    let mask = [];
    let dateFormat = this.props.t("dateFormat");
    if (dateFormat === "dateFormat") {
      dateFormat = consts.dateFormat;
    }
    if (dateFormat) {
      let tmp = dateFormat.split("");
      if (tmp && tmp.length > 0) {
        tmp.forEach(function (char) {
          if (char === "y" || char === "m" || char === "d") {
            mask.push(`\\d`);
          } else {
            if (char === "/") {
              mask.push(`\\/`);
            } else {
              mask.push(char);
            }
          }
        });
      }
    }
    return new RegExp(mask.join(""));
  }

  render() {
    const {
      placeholder,
      type,
      required,
      disabled,
      autoComplete,
      min,
      max,
      mask,
      rootClassName,
      t,
      errorMessage,
    } = this.props;
    const { displayPassword, value, showErrorMessage, error } = this.state;
    const finalClassName = classnames(rootClassName, style.root, {
      [style.login]: this.props.login,
    });

    let maskApplied = mask;
    if (type === InputType.MASK && !maskApplied) {
      maskApplied = this.getMaskFromConstDateFormat();
    }
    const direction = getFromLocal("layoutDirection");
    return (
      <Fragment>
        {type === InputType.MASK && (
          <MaskedInput
            className={finalClassName}
            type="text"
            placeholder={t(placeholder)}
            onBlur={this.handleChange.bind(this)}
            onFocus={this.handleFocus.bind(this)}
            onChange={this.handleChange.bind(this)}
            value={value}
            disabled={disabled}
            required={required}
            autoComplete={autoComplete}
            mask={maskApplied}
            // pipe={autoCorrectedDatePipe}
            keepCharPositions={false}
            guide={true}
            render={(ref, props) => (
              <input ref={(input) => ref(input)} {...props} />
            )}
          />
        )}
        {type === InputType.TEXT && (
          <input
            className={finalClassName}
            type="text"
            placeholder={t(placeholder)}
            onBlur={this.handleChange.bind(this)}
            onFocus={this.handleFocus.bind(this)}
            onChange={this.handleChange.bind(this)}
            value={value}
            required={required}
            autoComplete={autoComplete}
            disabled={disabled}
          />
        )}
        {type === InputType.EMAIL && (
          <input
            className={finalClassName}
            type="email"
            placeholder={t(placeholder)}
            onBlur={this.handleChange.bind(this)}
            onFocus={this.handleFocus.bind(this)}
            onChange={this.handleChange.bind(this)}
            value={value}
            required={required}
            autoComplete={"email"}
          />
        )}
        {type === InputType.NUMBER && (
          <input
            className={finalClassName}
            type="number"
            placeholder={t(placeholder)}
            onBlur={this.handleChange.bind(this)}
            onFocus={this.handleFocus.bind(this)}
            onChange={this.handleChange.bind(this)}
            value={value}
            required={required}
            autoComplete={autoComplete}
            min={min}
            max={max}
            disabled={disabled}
          />
        )}
        {type === InputType.PASSWORD && (
          <span className={style.passwordContainer}>
            {displayPassword && (
              <Fragment>
                <input
                  type="password"
                  className={finalClassName}
                  placeholder={t(placeholder)}
                  onBlur={this.handleChange.bind(this)}
                  onFocus={this.handleFocus.bind(this)}
                  onChange={this.handleChange.bind(this)}
                  value={value}
                  required={required}
                  autoComplete="none"
                  ref={(input) => {
                    this.inputSearch = input;
                  }}
                />
                <EyeOpened
                  className={classnames(style.passwordIcon, {
                    [style[direction?.toUpperCase()]]: true,
                  })}
                  onClick={this.togglePasswordField.bind(this)}
                />
              </Fragment>
            )}
            {!displayPassword && (
              <Fragment>
                <input
                  type="text"
                  className={finalClassName}
                  placeholder={t(placeholder)}
                  onBlur={this.handleChange.bind(this)}
                  onFocus={this.handleFocus.bind(this)}
                  onChange={this.handleChange.bind(this)}
                  value={value}
                  required={required}
                  autoComplete="none"
                />
                <EyeClosed
                  className={classnames(style.passwordIcon, {
                    [style[direction?.toUpperCase()]]: true,
                  })}
                  onClick={this.togglePasswordField.bind(this)}
                />
              </Fragment>
            )}
          </span>
        )}
        {showErrorMessage &&
          error &&
          error.length > 0 &&
          errorMessage === null && (
            <div>
              {this.state.error.map((item) => (
                <span key={item} className={style.error}>
                  <Trans>{item}</Trans>
                </span>
              ))}
            </div>
          )}
        {errorMessage !== null && (
          <div>
            <span className={style.error}>
              <Trans>{errorMessage}</Trans>
            </span>
          </div>
        )}
      </Fragment>
    );
  }
}

export const InputType = {
  TEXT: "text",
  NUMBER: "number",
  PASSWORD: "password",
  EMAIL: "email",
  MASK: "mask",
};

Input.defaultProps = {
  type: InputType.TEXT,
  required: false,
  autoComplete: "on",
};

Input.propTypes = {
  type: PropTypes.oneOf(Object.values(InputType)),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  pattern: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default withTranslation()(Input);
