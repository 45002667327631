import moment from "moment";
import { createSelector } from "reselect";

import tucanoConsts from "../consts/tucano";
import * as consts from "../consts/tucano";
import Asset, { LAYOUT_ACTIVE_ASSETS, LAYOUT_LANDSCAPE } from "../models/Asset";
import Channel from "../models/Channel";
import EPG from "../models/EPG";
import EPGEventAsset from "../models/EPGEventAsset";
import Season from "../models/Season";
import Strate, { STRATE_LAYOUT_GRID } from "../models/Strate";
import TVShow from "../models/TVShow";
import { selectCategoryForId, selectChannels, selectMenuOrder, selectParentCategoryForId } from "./reference";
import { selectListOptions, selectOptionValidity } from "./subscription";
// ----------------------------------------------------------------------------
// ACTIVE ASSETS
// ----------------------------------------------------------------------------
const selectHomeActiveAssets = createSelector(
  (state) => state?.content?.activeAssets?.data,
  (data) => data?.filter((activeAsset) => activeAsset.seekTime > 0) || []
);

const selectActiveAssets = (state, parameters) => {
  if (!state.content.activeAssets || !state.content.activeAssets.data) {
    return undefined;
  }
  const value = state.content.activeAssets.data.filter((activeAsset) => {
    if (parameters && parameters.seekTimeFilter === false) {
      return activeAsset;
    } else {
      return activeAsset.seekTime > 0;
    }
  });
  return value;
};

export const selectActiveAssetsTVOD = (state) => {
  if (!state?.content?.activeAssetsTVOD || !state?.content?.activeAssetsTVOD?.data) {
    return undefined;
  }
  return state.content.activeAssetsTVOD.data;
};

const selectCatchupContent = (state, parameters) => {
  if (!state || !state.content || !state.content.catchup || !state.content.catchup.data) {
    return [];
  }
  return state.content.catchup.data;
};
const selectHierarchy = (state, parameters) => {
  if (!state || !state.reference || !state.reference.hierarchy || !state.reference.hierarchy.data) {
    return [];
  }
  return state.reference.hierarchy.data;
};

export const getActiveAssets = createSelector(
  selectActiveAssets,
  selectListOptions,
  selectOptionValidity,
  selectActiveAssetsTVOD,
  selectMenuOrder,
  selectHierarchy,
  (data, listOptions, optionsValidity, activeAssetsTVOD, menuOrder, hierarchy) => {
    if (data) {
      return data.map((item) => {
        if (item.type === tucanoConsts.type.MOVIE && !item.directMetadata.AD_SERIESEA) {
          item.itemType = consts.ImageType.VOD_BACKGROUND;
        }
        if (
          item.type === tucanoConsts.type.MOVIE &&
          !item.directMetadata.AD_SERIESEA &&
          item.directMetadata.SA_CATCHUP
        ) {
          item.itemType = consts.ImageType.CATCHUP_THUMB;
        }
        if (item.type === tucanoConsts.type.EPISODE && item.directMetadata.AD_SERIESEA) {
          item.itemType = consts.ImageType.VOD_BACKGROUND;
        }
        if (item.type === tucanoConsts.type.SERIES && item.directMetadata.AD_ISSERIES) {
          item.itemType = consts.ImageType.VOD_BACKGROUND;
          item.serie = true;
        }
        const active = new Asset(item, LAYOUT_LANDSCAPE, hierarchy);

        active.setListOptions(listOptions);
        active.setOptionsValidity(optionsValidity);
        active.setActiveAssetTVOD(activeAssetsTVOD);
        active.setMenuOrder(menuOrder);
        return active;
      });
    }
  }
);

export const getActiveAssetsTVOD = createSelector(
  selectActiveAssetsTVOD,
  selectListOptions,
  selectOptionValidity,
  selectHierarchy,
  (data, listOptions, optionsValidity, hierarchy) => {
    if (data) {
      return data.map((item) => {
        if (item.type === tucanoConsts.type.MOVIE && !item.directMetadata.AD_SERIESEA) {
          item.itemType = consts.designMode == 169 ? consts.ImageType.VOD_BACKGROUND : consts.ImageType.VOD_THUMBNAIL;
        }
        if (
          item.type === tucanoConsts.type.MOVIE &&
          !item.directMetadata.AD_SERIESEA &&
          item.directMetadata.SA_CATCHUP
        ) {
          item.itemType =
            consts.designMode == 169 ? consts.ImageType.CATCHUP_BACKGROUND : consts.ImageType.CATCHUP_THUMB;
        }
        if (item.type === tucanoConsts.type.EPISODE && item.directMetadata.AD_SERIESEA) {
          item.itemType =
            consts.designMode == 169 ? consts.ImageType.VOD_CATEGORY_BACKGROUND : consts.ImageType.VOD_THUMBNAIL;
        }
        if (item.type === tucanoConsts.type.SERIES && item.directMetadata.AD_ISSERIES) {
          item.itemType = consts.designMode == 169 ? consts.ImageType.VOD_BACKGROUND : consts.ImageType.VOD_THUMBNAIL;
          item.serie = true;
        }
        const active = new Asset(item, LAYOUT_LANDSCAPE, hierarchy);

        active.setListOptions(listOptions);
        active.setOptionsValidity(optionsValidity);
        active.setActiveAssetTVOD(data);
        return active;
      });
    }
  }
);

export const getActiveAssetsStrate = createSelector(
  selectActiveAssets,
  selectListOptions,
  selectOptionValidity,
  selectActiveAssetsTVOD,
  selectHierarchy,
  (data, listOptions, optionsValidity, activeAssetsTVOD, hierarchy) => {
    if (data && data.length === 0) {
      return undefined;
    }
    if (data) {
      const strate = new Strate(data);
      data.forEach((item) => {
        if (item.type === tucanoConsts.type.MOVIE && !item.directMetadata.AD_SERIESEA) {
          item.itemType = consts.ImageType.VOD_BACKGROUND;
        }
        if (
          item.type === tucanoConsts.type.MOVIE &&
          !item.directMetadata.AD_SERIESEA &&
          item.directMetadata.SA_CATCHUP
        ) {
          item.itemType = consts.ImageType.CATCHUP_THUMB;
        }
        if (
          (item.type === tucanoConsts.type.EPISODE && item.directMetadata.AD_SERIESEA) ||
          (item.type === tucanoConsts.type.SERIES && item.directMetadata.AD_ISSERIES)
        ) {
          item.itemType = consts.ImageType.VOD_CATEGORY_BACKGROUND;
        }

        const active = new Asset(item, LAYOUT_ACTIVE_ASSETS, hierarchy);

        active.setListOptions(listOptions);
        active.setOptionsValidity(optionsValidity);
        active.setActiveAssetTVOD(activeAssetsTVOD);

        strate.pushItem(active);
      });
      return strate;
    }
  }
);

export const getVODActiveAssetsStrate = createSelector(
  selectActiveAssets,
  selectMenuOrder,
  selectListOptions,
  selectOptionValidity,
  selectActiveAssetsTVOD,
  selectCatchupContent,
  selectHierarchy,
  (data, menuOrder, listOptions, optionsValidity, activeAssetsTVOD, catchupContent, hierarchy) => {
    if (data && data.length === 0) {
      return undefined;
    }
    if (data) {
      const menuOrderVod = menuOrder?.filter((item) => {
        return item.idType === 2; /* idType for VOD */
      });
      let vodData = data;
      let series = {};
      if (catchupContent.length > 0) {
        vodData = data.filter((item) => {
          return item.catalog && menuOrderVod && item.catalog[0] === Math.abs(menuOrderVod[0].id);
        });
      }

      const strate = new Strate(vodData);
      vodData.forEach((item) => {
        if (item.type === tucanoConsts.type.MOVIE && !item.directMetadata.AD_SERIESEA) {
          item.itemType = consts.ImageType.VOD_BACKGROUND;
        }
        if (
          item.type === tucanoConsts.type.MOVIE &&
          !item.directMetadata.AD_SERIESEA &&
          item.directMetadata.SA_CATCHUP
        ) {
          item.itemType = consts.ImageType.CATCHUP_THUMB;
        }
        if (
          (item.type === tucanoConsts.type.EPISODE && item.directMetadata.AD_SERIESEA) ||
          (item.type === tucanoConsts.type.SERIES && item.directMetadata.AD_ISSERIES)
        ) {
          item.itemType = consts.ImageType.VOD_BACKGROUND;
        }

        const active = new Asset(item, LAYOUT_ACTIVE_ASSETS, hierarchy);

        active.setListOptions(listOptions);
        active.setOptionsValidity(optionsValidity);
        active.setMenuOrder(menuOrder);
        active.setActiveAssetTVOD(activeAssetsTVOD);

        if (typeof series[active.parentId] === "undefined" || typeof active.parentId === "undefined") {
          series[active.parentId] = item.idAsset;
          strate.pushItem(active);
        }
      });
      return strate;
    }
  }
);

// ----------------------------------------------------------------------------
// FAVORITES
// ----------------------------------------------------------------------------
const selectFavoritesChannels = (state, parameters) => {
  if (!state.content.favoritesChannels || !state.content.favoritesChannels.data) {
    return undefined;
  }
  return state.content.favoritesChannels.data;
};

export const getFavoritesChannels = createSelector(selectFavoritesChannels, (data) => {
  if (data) {
    return data.map((item) => {
      const favorite = new Channel(item);
      return favorite;
    });
  }
});
const selectFavorites = (state, parameters) => {
  if (!state.content.favorites || !state.content.favorites.data) {
    return undefined;
  }
  return state.content.favorites.data;
};

export const getFavorites = createSelector(
  selectFavorites,
  selectListOptions,
  selectOptionValidity,
  selectActiveAssetsTVOD,
  (data, listOptions, optionsValidity, activeAssetsTVOD) => {
    if (data) {
      return data.map((item) => {
        // For TVShow change itemType
        if (
          item.directMetadata &&
          (item.directMetadata.AD_SERIESEA || item.directMetadata.AD_ISSERIES || item.serie === true)
        ) {
          item.itemType = consts.ImageType.VOD_CATEGORY_THUMBNAIL;
        } else if (item.directMetadata && item.directMetadata.SA_CATCHUP) {
          item.itemType = consts.ImageType.CATCHUP_THUMB;
        } else {
          item.itemType = consts.ImageType.VOD_THUMBNAIL;
        }

        const favorite = new Asset(item);

        favorite.setListOptions(listOptions);
        favorite.setOptionsValidity(optionsValidity);
        favorite.setActiveAssetTVOD(activeAssetsTVOD);

        return favorite;
      });
    }
  }
);

// ----------------------------------------------------------------------------
// HOME
// ----------------------------------------------------------------------------
const selectHomeContent = (state, parameters) => {
  if (!state.content.home.data || !state.content.home.data.homecontent) {
    return [];
  }
  return state.content.home.data.homecontent;
};

const selectBaseId = (state, parameters) => {
  if (!state.content.home || !state.content.home.data) {
    return 0;
  }
  return state.content.home.data.baseId;
};

export const getHomeSlideshow = createSelector(
  selectHomeContent,
  selectBaseId,
  selectMenuOrder,
  selectActiveAssets,
  selectListOptions,
  selectOptionValidity,
  selectChannels,
  selectActiveAssetsTVOD,
  (data, baseId, menuOrder, activeAssets, listOptions, optionsValidity, channels, activeAssetsTVOD) => {
    if (data.length === 0) {
      return undefined;
    }
    const firstLine = data[0].line;
    const slideshowItems = data.filter((item) => {
      return (
        item.content &&
        item.content[0].tags &&
        item.content[0].tags.designMode &&
        item.content[0].tags.designMode[0] &&
        item.content[0].tags.designMode[0].includes("fullscreen") &&
        item.line === firstLine
      );
    });
    // Sort by columns
    slideshowItems.sort((itemA, itemB) => {
      return itemA.column - itemB.column;
    });

    const slides = [];

    if (slideshowItems) {
      for (let i = 0; i < slideshowItems.length; i++) {
        const item = slideshowItems[i];
        const data = item.content[0];

        if (
          data.idType === tucanoConsts.type.ASSET ||
          data.idType === tucanoConsts.type.CATEGORY ||
          data.idType === tucanoConsts.type.LINK
        ) {
          data.baseId = baseId;
          data.itemType = data.idType;
          let i = new Asset(data);

          if (activeAssets) {
            activeAssets.find((item) => {
              if (item.idAsset === i.getId()) {
                i.setSeekTime(item.seekTime);
                return true;
              }
              return false;
            });
          }

          i.setMenuOrder(menuOrder);
          i.setListOptions(listOptions);
          i.setOptionsValidity(optionsValidity);
          i.setActiveAssetTVOD(activeAssetsTVOD);

          slides.push(i);
        } else if (data.idType === tucanoConsts.type.CHANNEL) {
          const i = new Channel(data, baseId);

          if (channels && channels.length > 0) {
            const channel = channels.find((channel) => {
              return channel.idChannel === parseInt(data.content.idChannel, 10);
            });

            if (channel) {
              i.setSubscribed(channel.isSubscribed);
              i.setFta(channel.isFta);
              i.setLocalizeNumber(channel.localizeNumber);
            }
          }

          slides.push(i);
        } else if (data.idType === tucanoConsts.type.EPGBROADCAST) {
          const i = new EPGEventAsset(data);

          if (channels && channels.length > 0) {
            const channel = channels.find((channel) => {
              return channel.idChannel === parseInt(data.content.id_channel, 10);
            });

            if (channel) {
              i.setSubscribed(channel.isSubscribed);
              i.setFta(channel.isFta);

              slides.push(i);
            }
          }
        }
      }
    }
    return slides;
  }
);

export const getHomeStrates = createSelector(
  selectHomeContent,
  selectBaseId,
  selectMenuOrder,
  selectHomeActiveAssets,
  selectListOptions,
  selectOptionValidity,
  selectChannels,
  selectActiveAssetsTVOD,
  selectHierarchy,
  (data, baseId, menuOrder, activeAssets, listOptions, optionsValidity, channels, activeAssetsTVOD, hierarchy) => {
    const categoriesItems = data;
    let series = [];
    if (categoriesItems.length === 0) {
      return undefined;
    }

    // Sort by lines
    categoriesItems.sort((itemA, itemB) => {
      return itemA.line - itemB.line;
    });

    const strates = [];
    let prevLine = -1;
    let nextLine = -1;
    for (let i = 0; i < categoriesItems.length; i++) {
      const item = categoriesItems[i];
      const data = item.content[0];

      if (item.line !== prevLine) {
        if (data.idType === tucanoConsts.type.LABEL) {
          nextLine = item.line + 1;
          strates[item.line] = new Strate(data);
        } else {
          strates[item.line] = new Strate();
        }
        prevLine = item.line;
      }

      if (
        data.idType === tucanoConsts.type.ASSET ||
        data.idType === tucanoConsts.type.CATEGORY ||
        data.idType === tucanoConsts.type.LINK
      ) {
        data.baseId = baseId;
        data.itemType = data.idType;
        let asset = new Asset(data, "", hierarchy);
        if (activeAssets) {
          activeAssets.find((item) => {
            if (item.idAsset === asset.getId()) {
              asset.setSeekTime(item.seekTime);
              return true;
            }
            return false;
          });
        }

        asset.setMenuOrder(menuOrder);
        asset.setListOptions(listOptions);
        asset.setOptionsValidity(optionsValidity);
        asset.setActiveAssetTVOD(activeAssetsTVOD);

        let itemStrate = strates[nextLine == item.line ? item.line - 1 : item.line];
        if (itemStrate) {
          if (typeof asset.getParentId() !== "undefined" && typeof series[asset.getParentId()] === "undefined") {
            series[asset.parentId] = data.idItem;
            itemStrate.pushItem(asset);
          } else if (typeof asset.getParentId() === "undefined") {
            itemStrate.pushItem(asset);
          } else if (typeof asset.getParentId() !== "undefined") {
            itemStrate.pushItem(asset);
          }
        }
      } else if (data.idType === tucanoConsts.type.CHANNEL) {
        const i = new Channel(data, baseId);

        if (channels && channels.length > 0) {
          const channel = channels.find((channel) => {
            return channel.idChannel === parseInt(data.content.idChannel, 10);
          });

          if (channel) {
            i.setSubscribed(channel.isSubscribed);
            i.setFta(channel.isFta);
            i.setLocalizeNumber(channel.localizeNumber);
          }
        }

        let itemStrate = strates[nextLine == item.line ? item.line - 1 : item.line];

        if (itemStrate && i) {
          itemStrate.pushItem(i);
        }
      } else if (data.idType === tucanoConsts.type.EPGBROADCAST) {
        const i = new EPGEventAsset(data);

        if (channels && channels.length > 0) {
          const channel = channels.find((channel) => {
            return channel.idChannel === parseInt(data.content.id_channel, 10);
          });

          if (channel) {
            i.setSubscribed(channel.isSubscribed);
            i.setFta(channel.isFta);

            let itemStrate = strates[nextLine == item.line ? item.line - 1 : item.line];

            if (itemStrate && i) {
              itemStrate.pushItem(i);
            }
          }
        }
      }
    }

    return strates.filter((e) => e && e.items.length > 0);
  }
);

// ----------------------------------------------------------------------------
// VOD
// ----------------------------------------------------------------------------
const selectVODContent = (state, parameters) => {
  if (state.content.vod.loading) {
    return [];
  }
  return state.content.vod.data;
};

export const getVODSlideshow = createSelector(
  selectVODContent,
  selectActiveAssets,
  selectListOptions,
  selectOptionValidity,
  selectChannels,
  selectActiveAssetsTVOD,
  (data, activeAssets, listOptions, optionsValidity, channels, activeAssetsTVOD) => {
    if (!data) {
      return undefined;
    }
    // VOD homepage slideshow
    if (data.length > 0 && data[0] && data[0].items) {
      return data[0].items.map((item) => {
        let asset = undefined;
        if (item.type === "asset") {
          item.payload.itemType = consts.ImageType.VOD_THUMBNAIL;
          asset = new Asset(item.payload);
          asset.setListOptions(listOptions);
          asset.setOptionsValidity(optionsValidity);
          asset.setActiveAssetTVOD(activeAssetsTVOD);
        } else {
          asset = new TVShow(item.payload);
        }
        if (activeAssets) {
          activeAssets.find((item) => {
            if (item.idAsset === asset.getId()) {
              asset.setSeekTime(item.seekTime);
              return true;
            }
            return false;
          });
        }
        return asset;
      });
    }
    // VOD detail page slideshow
    if (
      data.items &&
      data.items.length > 0 &&
      data.items[0] &&
      data.items[0].payload &&
      data.items[0].payload.fictif === true &&
      data.items[0].payload.assets.length > 0
    ) {
      return data.items[0].payload.assets.map((item) => {
        item.itemType = consts.ImageType.VOD_BACKGROUND;
        let asset;
        if (item.serie !== undefined && item.serie) {
          asset = new TVShow(item);
        } else {
          asset = new Asset(item);
          asset.setListOptions(listOptions);
          asset.setOptionsValidity(optionsValidity);
          asset.setActiveAssetTVOD(activeAssetsTVOD);
        }
        if (activeAssets) {
          activeAssets.find((item) => {
            if (item.idAsset === asset.getId()) {
              asset.setSeekTime(item.seekTime);
              return true;
            }
            return false;
          });
        }
        return asset;
      });
    }
  }
);

export const getVODStrates = (state, containSlideshow) => {
  return createSelector(
    selectVODContent,
    selectActiveAssets,
    selectListOptions,
    selectOptionValidity,
    selectActiveAssetsTVOD,
    (data, activeAssets, listOptions, optionsValidity, activeAssetsTVOD) => {
      if (!data || data.length === 0) {
        return undefined;
      }
      // Remove slideshow category
      // let firstCatId = data[0]?.idItem;
      let categories = data;
      // if (data.length > 1 && containSlideshow) {
      //   categories = data.filter((item, index) => {
      //     return index !== 0 && item.idItem !== firstCatId;
      //   });
      // }
      if (categories.items) {
        categories = Object.values(categories.items);
      }

      // Asset or TVShow
      if (
        categories[0]?.type === "asset" ||
        (categories[0]?.type === "category" && categories[0]?.payload.serie === true)
      ) {
        const strate = new Strate({}, STRATE_LAYOUT_GRID);
        categories.forEach((item) => {
          if (item.payload.serie || item.type === "category") {
            item.payload.itemType = consts.ImageType.VOD_CATEGORY_THUMBNAIL;
          } else {
            item.payload.itemType = consts.ImageType.VOD_THUMBNAIL;
          }
          const asset = new Asset(item.payload);
          if (activeAssets) {
            activeAssets.find((item) => {
              if (item.idAsset === asset.getId()) {
                asset.setSeekTime(item.seekTime);
                return true;
              }
              return false;
            });
          }

          asset.setListOptions(listOptions);
          asset.setOptionsValidity(optionsValidity);
          asset.setActiveAssetTVOD(activeAssetsTVOD);

          strate.pushItem(asset);
        });
        return [strate];
      } else {
        return (
          categories
            ?.map((category) => {
              let data = category;
              if (data.payload) {
                data = data.payload;
              }
              if (data.fictif) {
                return undefined;
              }
              const strate = new Strate(data, Strate.LAYOUT_LINE);
              let assets = category.items;
              if (category.payload && category.payload.assets) {
                assets = category.payload.assets;
              }
              assets?.forEach((item) => {
                let itemType = consts.ImageType.VOD_THUMBNAIL;
                if (item.type === "category" || item.assetType === "category") {
                  itemType = consts.ImageType.VOD_CATEGORY_THUMBNAIL;
                }
                if (item.payload) {
                  item.payload.itemType = itemType;
                  item.payload.assetType = item.assetType || item.type || category.type;
                } else {
                  item.itemType = itemType;
                  item.assetType = item.assetType || item.type || category.type;
                }
                const asset = new Asset(item.payload || item);
                if (activeAssets) {
                  activeAssets.find((item) => {
                    if (item.idAsset === asset.getId()) {
                      asset.setSeekTime(item.seekTime);
                      return true;
                    }
                    return false;
                  });
                }

                asset.setListOptions(listOptions);
                asset.setOptionsValidity(optionsValidity);
                asset.setActiveAssetTVOD(activeAssetsTVOD);

                strate.pushItem(asset);
              });
              return strate;
            })
            .filter(Boolean) || []
        );
      }
    }
  )(state, containSlideshow);
};

// ----------------------------------------------------------------------------
// EPG
// ----------------------------------------------------------------------------
const selectEPGs = (state, parameters) => {
  if (!state.content.epg || !state.content.epg.data) {
    return {};
  }
  return state.content.epg.data;
};
export const getGroups = (state) => {
  return createSelector(selectChannels, (channels) => {
    if (!channels || channels.length === 0) {
      return undefined;
    }
    let groups = [];
    channels?.forEach((channel) => {
      channel.tags.find((item) => {
        //fix WRONG data structure sent by the back end
        let result = item;
        if (item.includes("group:")) {
          result = item.replace(/group:\["([^"]+)"\]/, "$1"); //Remove the group word and special characters if exists
        }
        if (!groups.includes(result)) {
          groups.push(result);
        }
      });
    });

    //add channels count per group
    let groupsWithChannelCount = [];
    groups?.forEach((group) => {
      let count = 0;
      channels?.forEach((channel) => {
        let groupArray = [];
        if (channel?.tags?.length > 0) {
          channel.tags.find((item) => {
            //fix WRONG data structure sent by the back end
            let result = item;
            if (item.includes("group:")) {
              result = item.replace(/group:\["([^"]+)"\]/, "$1"); //Remove the group word and special characters if exists
            }
            groupArray.push(result);
          });
          if (groupArray.includes(group)) {
            count++;
          }
        }
      });
      groupsWithChannelCount.push({ group: group, count: count });
    });
    groupsWithChannelCount.push({ group: "All channels", count: channels.length });
    //sort groups alphabetically
    const sortedGroups = groupsWithChannelCount.sort(function (a, b) {
      var textA = a.group.toUpperCase();
      var textB = b.group.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return sortedGroups;
  })(state);
};
export const getEPG = (state, EPGDate = new Date(), durationEPGDisplayImage = 1) => {
  return createSelector(selectChannels, selectEPGs, (channels, epgs) => {
    if (!channels || channels.length === 0 || !epgs || epgs.length === 0) {
      return undefined;
    }

    // Sort channels
    const sortedChannels = channels.sort((a, b) => {
      return a.localizeNumber - b.localizeNumber;
    });

    // Filter active channels
    const activeEPGs = sortedChannels.map((channel) => {
      return epgs["chan_" + channel.idChannel] || [];
    });

    let channelCounter = 0;
    const data = sortedChannels
      .map((item) => {
        const index = channelCounter++;
        // Insert epgs at the right position
        const channelEPGs = activeEPGs[index];

        // Create object for response
        const channel = new Channel(item, undefined, 100);

        if (channels && channels.length > 0) {
          const listChannel = channels.find((channel) => {
            return channel.idChannel === parseInt(item.idChannel, 10);
          });

          if (listChannel) {
            channel.setSubscribed(listChannel.isSubscribed);
            channel.setFta(listChannel.isFta);
          }
        }

        // When the channel has no EPG we don't want to add empty EPG padding
        // It's used to display an error message in EPG Grid
        if (activeEPGs[index].length === 0) {
          return channel;
        }
        channelEPGs.forEach((item) => {
          const epgItem = new EPG(item);
          if (epgItem.shouldDisplayImage(durationEPGDisplayImage)) {
            epgItem.setDisplayImage(true);
          } else {
            epgItem.setDisplayImage(false);
          }
          channel.addEPG(epgItem);
        });
        return channel;
      })
      .filter(Boolean);
    return data;
  })(state, EPGDate);
};
export const getFilteredEpg = (
  state,
  EPGDate = new Date(),
  group,
  sort,
  term,
  directAraayForFavorites,
  favoriteChannels,
  durationEPGDisplayImage = 1
) => {
  return createSelector(selectChannels, selectEPGs, selectFavoritesChannels, (channels, epgs, favorites) => {
    if (!channels || channels.length === 0 || !epgs || epgs.length === 0) {
      return undefined;
    }
    //filter channels
    let filteredChannels = channels;
    if (group === "Favorites" && favorites && favorites.length > 0) {
      const favoritesIds = favorites.map((one) => one.idChannel);
      filteredChannels = filteredChannels.filter((channel) => favoritesIds.indexOf(channel.idChannel) >= 0);
    } else if (group !== null) {
      filteredChannels = channels.filter((channel) => {
        let groups = [];
        if (channel.tags && channel.tags.length > 0) {
          channel.tags?.forEach((tag) => {
            let result = tag;
            if (tag?.includes("group:")) {
              // fix stupid backend data structure
              result = tag.replace(/group:\["([^"]+)"\]/, "$1"); //Remove the group word and special characters if exists
            }
            groups.push(result);
          });
          if (groups && groups.includes(group)) {
            return channel;
          }
        }
      });
    }
    const EPGDateTimeStamp = moment(EPGDate).set({ m: 0 }).unix();
    const filteredEpgData = {};
    Object.keys(epgs).forEach((epgKey) => {
      filteredEpgData[epgKey] = epgs[epgKey].filter((data) => {
        return (EPGDateTimeStamp >= data.startutc && EPGDateTimeStamp <= data.endutc) || EPGDateTimeStamp < data.endutc;
      });
    });
    // Sort channels
    let sortedChannels = [];
    switch (sort) {
      case "N° descending":
        sortedChannels = filteredChannels.sort((a, b) => {
          return b.localizeNumber - a.localizeNumber;
        });
        break;
      case "Name ascending":
        sortedChannels = filteredChannels.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        });
        break;
      case "Name descending":
        sortedChannels = filteredChannels.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
          return 0;
        });
        break;
      case "Favorites first":
        sortedChannels = filteredChannels.reduce((start, chan) => {
          if (favoriteChannels?.filter((c) => c.idChannel === chan.idChannel).length > 0) {
            return [chan, ...start];
          } else {
            return [...start, chan];
          }
        }, []);
        break;
      default:
        // Default is the ascending sort by Channel localizeNumber
        sortedChannels = filteredChannels.sort((a, b) => {
          return a.localizeNumber - b.localizeNumber;
        });
        break;
    }
    //search channel
    let channelFound = sortedChannels;
    let atLeastOneFound = false;
    if (term !== "") {
      channelFound = channelFound.reduce((start, chan) => {
        if (chan.name.toUpperCase().includes(term?.toUpperCase())) {
          let found = { ...chan };
          found.found = true;
          atLeastOneFound = true;
          start.push(found);
        } else {
          start.push(chan);
        }
        return start;
      }, []);
    }
    // Filter active channels
    const activeEPGs = channelFound?.map((channel) => {
      // return epgs["chan_" + channel.idChannel] || [];
      return filteredEpgData["chan_" + channel.idChannel] || [];
    });
    const activeEPGsSorted = sortedChannels?.map((channel) => {
      // return epgs["chan_" + channel.idChannel] || [];
      return filteredEpgData["chan_" + channel.idChannel] || [];
    });

    let channelCounter = 0;
    const sorted = sortedChannels
      ?.map((item) => {
        const index = channelCounter++;
        // Insert epgs at the right position
        const channelEPGs = activeEPGsSorted[index];

        // Create object for response
        const channel = new Channel(item, undefined, 100);

        if (channels && channels.length > 0) {
          const listChannel = channels.find((channel) => {
            return channel.idChannel === parseInt(item.idChannel, 10);
          });

          if (listChannel) {
            channel.setSubscribed(listChannel.isSubscribed);
            channel.setFta(listChannel.isFta);
          }
        }

        // When the channel has no EPG we don't want to add empty EPG padding
        // It's used to display an error message in EPG Grid
        if (activeEPGsSorted[index].length === 0) {
          return channel;
        }
        channelEPGs.forEach((item) => {
          const epgItem = new EPG(item);
          if (epgItem.shouldDisplayImage(durationEPGDisplayImage)) {
            epgItem.setDisplayImage(true);
          } else {
            epgItem.setDisplayImage(false);
          }
          channel.addEPG(epgItem);
        });
        return channel;
      })
      .filter(Boolean);
    channelCounter = 0;
    const found = channelFound
      ?.map((item) => {
        const index = channelCounter++;
        // Insert epgs at the right position
        const channelEPGs = activeEPGs[index];

        // Create object for response
        const channel = new Channel(item, undefined, 100);

        if (channels && channels.length > 0) {
          const listChannel = channels.find((channel) => {
            return channel.idChannel === parseInt(item.idChannel, 10);
          });

          if (listChannel) {
            channel.setSubscribed(listChannel.isSubscribed);
            channel.setFta(listChannel.isFta);
          }
        }

        // When the channel has no EPG we don't want to add empty EPG padding
        // It's used to display an error message in EPG Grid
        if (activeEPGs[index].length === 0) {
          return channel;
        }
        channelEPGs.forEach((item) => {
          const epgItem = new EPG(item);
          if (epgItem.shouldDisplayImage(durationEPGDisplayImage)) {
            epgItem.setDisplayImage(true);
          } else {
            epgItem.setDisplayImage(false);
          }
          channel.addEPG(epgItem);
        });
        return channel;
      })
      .filter(Boolean);
    return { sortedChannels: sorted, foundChannels: found, atLeastOneFound: atLeastOneFound };
  })(state, EPGDate);
};

// ----------------------------------------------------------------------------
// ASSETS
// ----------------------------------------------------------------------------
const selectAsset = (state, parameters) => {
  if (!state.content.asset || !state.content.asset.data) {
    return undefined;
  }
  return state.content.asset.data;
};

const selectAssetTrailer = (state, parameters) => {
  if (!state.content.asset || !state.content.asset.trailer) {
    return undefined;
  }
  return state.content.asset.trailer;
};

const selectAssetSlideTrailer = (state, parameters) => {
  if (!state.content.asset || !state.content.asset.sliderTrailer) {
    return undefined;
  }
  return state.content.asset.sliderTrailer;
};

const selectShowSliderTrailer = (state, parameters) => {
  if (!state.content.showSliderTrailer) {
    return undefined;
  }
  return state.content.showSliderTrailer;
};

const selectCurrentSliderTrailer = (state, parameters) => {
  if (!state.content.currentTrailerIdSlider) {
    return undefined;
  }
  return state.content.currentTrailerIdSlider;
};
const selectNextEpisode = (state, parameters) => {
  if (!state.content.asset || !state.content.asset.nextEpisode) {
    return undefined;
  }
  return state.content.asset.nextEpisode;
};

const selectAssetMonolistRecommendation = (state) => {
  if (!state.content.asset || !state.content.asset.monolistRecommendation) {
    return {};
  }
  return state.content.asset.monolistRecommendation;
};

const selectAssetMultifacetRecommendation = (state) => {
  if (!state.content.asset || !state.content.asset.multifacetRecommendation) {
    return {};
  }
  return state.content.asset.multifacetRecommendation;
};

export const getAsset = createSelector(
  selectAsset,
  selectActiveAssets,
  selectAssetMonolistRecommendation,
  selectAssetMultifacetRecommendation,
  selectMenuOrder,
  selectListOptions,
  selectOptionValidity,
  selectActiveAssetsTVOD,
  (
    data,
    activeAssets,
    monolistRecommendation,
    multifacetRecommendation,
    menuOrder,
    listOptions,
    optionsValidity,
    activeAssetsTVOD
  ) => {
    if (data) {
      data.itemType = consts.ImageType.VOD_THUMBNAIL;
      data.monolistRecommendation = monolistRecommendation;
      data.multifacetRecommendation = multifacetRecommendation;
      data.listOptions = listOptions || [];
      data.activeAssetsTVOD = activeAssetsTVOD || [];

      const asset = new Asset(data);

      asset.setMenuOrder(menuOrder);
      asset.setListOptions(listOptions);
      asset.setOptionsValidity(optionsValidity);
      asset.setActiveAssetTVOD(activeAssetsTVOD);

      if (activeAssets && activeAssets.length > 0) {
        activeAssets.find((activeAsset) => {
          if (activeAsset.idAsset === asset.getId()) {
            asset.setSeekTime(activeAsset.seekTime);
            return true;
          }
          return false;
        });
      }

      return asset;
    }
  }
);

export const getAssetTrailer = (state) => {
  return createSelector(selectAssetTrailer, (trailer) => {
    if (trailer && trailer.data) {
      return trailer.data;
    }
  })(state);
};

export const getAssetSlideTrailer = (state) => {
  return createSelector(selectAssetSlideTrailer, (slideTrailer) => {
    if (slideTrailer && slideTrailer.data) {
      return slideTrailer.data;
    }
  })(state);
};

export const getShowSliderTrailer = (state) => {
  return createSelector(selectShowSliderTrailer, (showSliderTrailer) => {
    if (showSliderTrailer) {
      return showSliderTrailer;
    }
  })(state);
};

export const getAssetNextEpisode = (state) => {
  return createSelector(
    selectNextEpisode,
    selectListOptions,
    selectOptionValidity,
    selectActiveAssetsTVOD,
    (data, listOptions, optionsValidity, activeAssetsTVOD) => {
      if (data) {
        data.itemType = consts.ImageType.VOD_THUMBNAIL;
        const asset = new Asset(data);
        asset.setListOptions(listOptions);
        asset.setOptionsValidity(optionsValidity);
        asset.setActiveAssetTVOD(activeAssetsTVOD);
        return asset;
      }
    }
  )(state);
};

export const getAssetBreadcrumb = (state, catalog) => {
  return createSelector(selectAsset, (asset) => {
    const breadcrumb = [];

    if (asset && asset.negpath) {
      const path = asset.negpath[0].split("/");
      breadcrumb.push({
        /*TODO Add translation*/
        title: "VOD",
        url: "/vod",
      });
      const typeCategory = selectCategoryForId(state.reference.hierarchy.data, parseInt(path[1]));
      if (typeCategory && typeCategory.hasOwnProperty("name")) {
        breadcrumb.push({
          title: typeCategory.name,
          url: `/vod/${typeCategory.idCatalog}`,
        });
      }
    }
    return breadcrumb;
  })(state, catalog);
};

export const getCategoryBreadcrumb = (state, catalog, tvShowId) => {
  return createSelector(
    () => {
      return selectParentCategoryForId(state.reference.hierarchy.data, parseInt(tvShowId));
    },
    () => {
      return selectCategoryForId(state.reference.hierarchy.data, parseInt(tvShowId));
    },
    (parent, asset) => {
      const breadcrumb = [];
      if (asset) {
        breadcrumb.push({
          /*TODO Add translation*/
          title: "VOD",
          url: "/vod",
        });
      }
      if (parent && parent.idCatalog > 0) {
        breadcrumb.push({
          /*TODO Add translation*/
          title: "VOD",
          url: `/vod/${parent.idCatalog}`,
        });
      }
      return breadcrumb;
    }
  )(state, catalog, tvShowId);
};

const selectEPG = (state, parameters) => {
  if (!state.content.assetEPG || !state.content.assetEPG.data) {
    return undefined;
  }
  return state.content.assetEPG.data;
};

export const getEPGDetail = createSelector(selectEPG, selectChannels, (data, channels) => {
  if (data) {
    const epg = new EPG(data);

    if (channels && channels.length > 0) {
      const channel = channels.find((channel) => {
        return channel.idChannel === parseInt(data.idChannel[0], 10);
      });

      if (channel) {
        epg.channelName = channel.name;
        epg.setSubscribed(channel.isSubscribed);
        epg.setFta(channel.isFta);
      }
    }

    return epg;
  }
});

export const isAssetFavorite = createSelector(selectFavorites, selectAsset, (favorites, asset) => {
  if (favorites && asset) {
    const favoriteAsset = favorites.find((item) => {
      return item.idAsset === asset.idAsset;
    });
    return favoriteAsset !== undefined;
  }
});

const selectTvShowSeasons = (state, parameters) => {
  if (!state.content.tvshow || !state.content.tvshow.data) {
    return undefined;
  }
  return state.content.tvshow.data;
};

export const getTVshowAssetsFavorite = (state, parameters) => {
  const tvShowId = parseInt(parameters);
  return createSelector(selectFavorites, (favorites) => {
    let favoriteAsset;
    if (favorites) {
      favoriteAsset = favorites.find((favorite) => {
        return favorite.idCatalog === tvShowId;
      });
    }
    return favoriteAsset !== undefined;
  })(state, parameters);
};

const selectActiveAssetsAll = (state) => {
  if (!state.content.activeAssets || !state.content.activeAssets.data) {
    return undefined;
  }
  return state.content.activeAssets.data.map((one) => one.idAsset);
};

export const getTvShowSeasons = (state, tvShowId) => {
  return createSelector(
    selectTvShowSeasons,
    selectActiveAssets,
    selectListOptions,
    selectOptionValidity,
    selectActiveAssetsTVOD,
    selectActiveAssetsAll,
    (seasons, activeAssets, listOptions, optionsValidity, activeAssetsTVOD, allActiveAssets) => {
      const tvshow = [];
      if (seasons) {
        Object.values(seasons).map((item) => {
          let itemType = 540;
          item.idParent = tvShowId;
          if (item.assets) {
            const season = new Season(item, itemType);
            item.assets.forEach((episode) => {
              const asset = new Asset(episode);
              if (allActiveAssets && allActiveAssets.indexOf(asset.getId()) > -1) asset.setIsAllowed(true);
              if (activeAssets && activeAssets.length > 0) {
                activeAssets.find((activeAsset) => {
                  if (activeAsset.idAsset === asset.getId()) {
                    asset.setSeekTime(activeAsset.seekTime);
                    return true;
                  }
                  return false;
                });
              }

              asset.setListOptions(listOptions);
              asset.setOptionsValidity(optionsValidity);
              asset.setActiveAssetTVOD(activeAssetsTVOD);

              season.addAsset(asset);
            });
            tvshow.push(season);
          }
          return tvshow;
        });
        return tvshow;
      }
    }
  )(state, tvShowId);
};

// ----------------------------------------------------------------------------
// LIVE
// ----------------------------------------------------------------------------
export const getLive = createSelector(selectChannels, selectEPGs, (channels, epgs) => {
  if (!channels || channels.length === 0 || !epgs || epgs.length === 0) {
    return undefined;
  }

  const now = Math.floor(new Date().getTime() / 1000);

  // Filter active channels
  const livePrograms = channels
    .sort((a, b) => {
      return a.localizeNumber - b.localizeNumber;
    })
    .map((channel) => {
      const epg = epgs["chan_" + channel.idChannel];
      if (!epg) {
        return undefined;
      }
      const liveEPG = epg.find((item) => {
        return item.startutc < now && now < item.endutc;
      });
      if (!liveEPG) {
        return undefined;
      }
      return new EPG(liveEPG);
    })
    .filter(Boolean);
  return livePrograms;
});

// ----------------------------------------------------------------------------
// CATCHUP CHANNEL
// ----------------------------------------------------------------------------

export const getCatchupChannelSlideshow = createSelector(
  selectCatchupContent,
  selectMenuOrder,
  selectActiveAssets,
  selectListOptions,
  selectOptionValidity,
  selectActiveAssetsTVOD,
  (data, menuOrder, activeAssets, listOptions, optionsValidity, activeAssetsTVOD) => {
    if (!data.length === 0) {
      return undefined;
    }
    const slideshowItems = data.find((item, index) => {
      return item.title === "_default";
    });

    if (slideshowItems) {
      return slideshowItems.items.map((item) => {
        const data = item.payload;
        data.itemType = consts.ImageType.CATCHUP_THUMB;

        const asset = new Asset(data);

        if (activeAssets) {
          activeAssets.find((item) => {
            if (item.idAsset === asset.getId()) {
              asset.setSeekTime(item.seekTime);
              return true;
            }
            return false;
          });
        }

        asset.setMenuOrder(menuOrder);
        asset.setListOptions(listOptions);
        asset.setOptionsValidity(optionsValidity);
        asset.setActiveAssetTVOD(activeAssetsTVOD);

        return asset;
      });
    }
    return undefined;
  }
);

export const getCatchupChannelStrates = createSelector(
  selectCatchupContent,
  selectMenuOrder,
  selectActiveAssets,
  selectListOptions,
  selectOptionValidity,
  selectActiveAssetsTVOD,
  (data, menuOrder, activeAssets, listOptions, optionsValidity, activeAssetsTVOD) => {
    if (data.length === 0) {
      return undefined;
    }

    const categoriesItems = data.filter((item, index) => {
      return item.title !== "_default";
    });

    const strates = categoriesItems
      .map((item) => {
        if (item.items.length === 0) {
          return undefined;
        }

        item.catchupDate = item.title;

        const strate = new Strate(item);

        item.items.forEach((asset) => {
          if (asset.type === "asset") {
            const data = asset.payload;
            data.itemType = consts.ImageType.CATCHUP_THUMB;

            const a = new Asset(data, LAYOUT_LANDSCAPE);

            if (activeAssets) {
              activeAssets.find((item) => {
                if (item.idAsset === a.getId()) {
                  a.setSeekTime(item.seekTime);
                  return true;
                }
                return false;
              });
            }

            a.setMenuOrder(menuOrder);
            a.setListOptions(listOptions);
            a.setOptionsValidity(optionsValidity);
            a.setActiveAssetTVOD(activeAssetsTVOD);

            strate.pushItem(a);
          }
        });
        return strate;
      })
      .filter(Boolean);
    return strates;
  }
);

export const getCatchupDetailStrate = (state, paramId) => {
  return createSelector(
    selectCatchupContent,
    selectMenuOrder,
    selectActiveAssets,
    selectListOptions,
    selectOptionValidity,
    selectActiveAssetsTVOD,
    (data, menuOrder, activeAssets, listOptions, optionsValidity, activeAssetsVOD) => {
      if (data.length === 0) {
        return undefined;
      }
      let strate;
      for (const item of data) {
        if ((item.id || item.idItem) === Number(paramId)) {
          strate = new Strate(item);
          item.items.forEach((asset) => {
            if (asset.type === "asset") {
              const data = asset.payload;
              data.itemType = consts.ImageType.CATCHUP_THUMB;
              const a = new Asset(data, LAYOUT_LANDSCAPE);
              if (activeAssets) {
                activeAssets.find((item) => {
                  if (item.idAsset === a.getId()) {
                    a.setSeekTime(item.seekTime);
                    return true;
                  }
                  return false;
                });
              }
              a.setMenuOrder(menuOrder);
              a.setListOptions(listOptions);
              a.setOptionsValidity(optionsValidity);
              a.setActiveAssetTVOD(activeAssetsVOD);

              strate.pushItem(a);
            }
          });
          break;
        }
      }
      return strate;
    }
  )(state, paramId);
};

export const getNextEPGForChannelId = (state, channelId) => {
  return createSelector(selectEPGs, (epgs) => {
    const currentChannelId = `chan_${channelId}`;
    if (!epgs || epgs.length === 0 || !epgs[currentChannelId]) {
      return undefined;
    }
    const channel = epgs[currentChannelId];
    const now = new Date();
    const nextEPG = channel.find((item) => {
      return item.startutc * 1000 > now.getTime();
    });
    if (nextEPG) {
      return new EPG(nextEPG);
    }
  })(state, channelId);
};

export const getCurrentSliderTrailer = (state) => {
  return createSelector(selectCurrentSliderTrailer, (currentTrailerIdSlider) => {
    if (currentTrailerIdSlider) {
      return currentTrailerIdSlider;
    }
  })(state);
};
