import "react-virtualized/styles.css";
import "moment/locale/fr";
import "moment/locale/pt";

import { setDeviceLimitError } from "actions/session";
import {
  setApplicationPreviousRoute,
  setApplicationPreviousStableRoute,
  setApplicationReady,
  setApplicationViewport,
  setErrorShow,
  setLoginShow,
  setMenuOpen,
} from "actions/ui";
import {
  addToast,
  removeAllToast,
  removeToast,
  resetHistoryLink,
  setChannelFilter,
  setHistoryLink,
} from "actions/ui";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import classnames from "classnames";
import DevicesManager from "components/DevicesManager";
import ErrorHandler from "components/ErrorHandler";
import ErrorMessage from "components/ErrorMessage";
import Footer from "components/Footer";
import Loader from "components/Loader";
import Menu from "components/Menu";
import Modal from "components/Modal";
import Search from "components/Search";
import consts from "consts/consts";
import ModalSubscription from "containers/ModalSubscription";
import ModalValidateParentalCode from "containers/ModalValidateParentalControl";
import PageConsent from "containers/PageConsent";
import PageLogin from "containers/PageSignIn";
import Profile from "containers/Profile";
import Toasts from "containers/Toasts";
import Transaction from "containers/Transaction";
import { detect } from "detect-browser";
import { sendAnalytics } from "helpers/analytics";
import { isConnectedFromLocalISP } from "helpers/localIsp";
import { getFromLocal } from "helpers/localStorage";
import { saveInLocal } from "helpers/localStorage";
import { shouldDisplayMenu, shouldDisplaySearchIcon } from "helpers/url";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import React, { Fragment, PureComponent } from "react";
import ReactPixel from "react-facebook-pixel";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { matchPath, withRouter } from "react-router";
import { renderRoutes } from "react-router-config";
import { compose } from "redux";
import actionTypes from "web-api/consts/actionTypes";
import { generateImageWithNewConfig } from "web-api/helpers/imageHelper";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

const MODAL_NEW_SUBSCRIPTION = "MODAL_NEW_SUBSCRIPTION";
const MODAL_PARENTAL_CONTROL = "MODAL_PARENTAL_CONTROL";
const MODAL_PROFILE_SELECTION = "MODAL_PROFILE_SELECTION";
const MODAL_CONSENT = "MODAL_CONSENT";

class App extends PureComponent {
  state = {
    isMenuOpen: false,
    isSearchOpen: false,
    productId: getFromLocal("productId") || consts.productId || null,
    show: false,
    modalType: undefined,
    assetSubscription: undefined,
    prevLocation: "",
    ssoLoading: false,
    manageConsentListOpned: false,
  };

  errorAPIcasRemoveDevice = {
    "-1": "Invalid auth",
    "-10": "Device not found",
    "-11":
      "The maximum number of device removal has been reached,Please use a device linked to your account",
  };

  getErrorAPI(api, code) {
    const { t } = this.props;
    if (api === "casRemoveDevice" && this.errorAPIcasRemoveDevice[code]) {
      return t(this.errorAPIcasRemoveDevice[code]);
    } else {
      sendAnalytics(
        this.props.dispatch,
        "ERROR",
        "9000",
        "MESSAGING",
        JSON.stringify({
          sourceComponent: "",
          backendCode: api || null,
          frontendCode: "" || null,
          middlewareCode: "" || null,
          firmwareCode: "" || null,
          agentCode: "" || null,
          watchDogCode: "" || null,
          messageText: "" || null,
          errorUid: code || null,
        })
      );
      return t("An error occured,Please retry later");
    }
  }

  trackPageview(pathname) {
    window.gtag("config", consts.tagManagerId, {
      page_path: pathname,
    });
  }

  async componentDidMount() {
    const { isConnected, t, location, dispatch } = this.props;
    let languageId;
    const concentList = getFromLocal("concentList");

    const search = new URLSearchParams(window.location.search);

    const state = search.get("state") || "";
    const code = search.get("code") || "";

    const rootElement = document.documentElement;
    document
      .querySelector("head .favicon")
      .setAttribute(
        "href",
        generateImageWithNewConfig("/assets/favicon.ico", "favicon")
      );
    // check version backend then apply config (logo & colors)
    if (Math.round(consts?.versionBackend) >= 4) {
      if (consts?.colors) {
        rootElement.style.setProperty(
          "--color-primary1",
          consts?.colors?.primary1
        );
        rootElement.style.setProperty(
          "--color-primary2",
          consts?.colors?.primary2
        );
        rootElement.style.setProperty(
          "--color-primary3",
          consts?.colors?.primary3
        );
        rootElement.style.setProperty(
          "--color-primary4",
          consts?.colors?.primary4
        );
        rootElement.style.setProperty(
          "--color-secondary1",
          consts?.colors?.secondary1
        );
        rootElement.style.setProperty(
          "--color-secondary2",
          consts?.colors?.secondary2
        );
        rootElement.style.setProperty(
          "--color-secondary3",
          consts?.colors?.secondary3
        );
        rootElement.style.setProperty(
          "--color-secondary4",
          consts?.colors?.secondary4
        );
        rootElement.style.setProperty(
          "--color-highlight-hover1",
          consts?.colors?.highlight1
        );
        rootElement.style.setProperty(
          "--color-highlight-hover2",
          consts?.colors?.highlight2
        );
        rootElement.style.setProperty("--color-error", consts?.colors?.error);
        rootElement.style.setProperty("--color-relax", consts?.colors?.relax);
        rootElement.style.setProperty(
          "--color-gradient1",
          `linear-gradient(90deg, ${consts?.colors?.top} 0%, ${consts?.colors?.bottom})`
        );
        rootElement.style.setProperty(
          "--color-gradient2",
          consts?.colors?.gradient2
        );
      }
    }

    dispatch(removeAllToast());

    if (window.opener && code && state === "ssoScial:auth:done") {
      window.opener.postMessage(
        {
          type: "ssoScial:auth:done",
          payload: code,
        },
        window.location.origin
      );
    }

    if (consts.loginWithSSO && window.opener && code) {
      this.setState({ ssoLoading: true });
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-AN-WebService-IdentityKey", consts.apiKey);
      const raw = JSON.stringify({
        code,
        redirect_uri: consts.SSORedirectURI,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };
      const ssoObject = consts.SSOConfig.find((one) => one.clientID === state);
      try {
        if (ssoObject?.callbackAPI) {
          let response = await fetch(ssoObject.callbackAPI, requestOptions);
          response = await response.json();
          window.opener.postMessage(
            {
              type: "sso:auth:done",
              payload: response,
              tveProviderId: ssoObject.tveProviderId,
              tveAdapter: ssoObject.tveAdapter,
            },
            consts.SSORedirectURI
          );
          //dispatch(ssoCodeRecieved(code));
        }
        window.close();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    } else {
      if (!isConnected) {
        const privacyAndTermsLinks =
          [consts.routes.privacy.url.toLocaleLowerCase()].includes(
            location.pathname.toLocaleLowerCase()
          ) ||
          [consts.routes.cgu.url.toLocaleLowerCase()].includes(
            location.pathname.toLocaleLowerCase()
          );
        const historyLink = `${location.pathname}${location.search}`;
        if (
          ![
            consts.routes.signIn.url,
            consts.routes.logout.url,
            consts.routes.signup.url,
          ].includes(location.pathname)
        )
          dispatch(setHistoryLink(historyLink));

        dispatch(setChannelFilter({ value: null, profile: null }, isConnected));
        if (
          !consts.guestModeEnabled &&
          ![
            consts.routes.resetpassword.url.toLocaleLowerCase(),
            consts.routes.confirmAccount.url.toLocaleLowerCase(),
          ].includes(location.pathname.toLocaleLowerCase()) &&
          !privacyAndTermsLinks
        ) {
          this.props.history.push(consts.routes.signIn.url);
        } else if (
          !consts.guestModeEnabled &&
          consts.landOnLogin &&
          ![
            consts.routes.resetpassword.url.toLocaleLowerCase(),
            consts.routes.confirmAccount.url.toLocaleLowerCase(),
            consts.routes.signup.url.toLocaleLowerCase(),
          ].includes(location.pathname.toLocaleLowerCase()) &&
          !privacyAndTermsLinks
        ) {
          // window.onpopstate = (_e) => {
          //   //this.props.history.replace({ pathname: this.state.prevLocation, search: "?login=0" });
          // };
          this.props.history.push(consts.routes.signIn.url);
        }
      }

      document.title = consts.name;
      if (consts.appTitle) {
        document.title = consts.appTitle;
      }

      // initialize google analytics script tag
      if (consts.tagManagerId && concentList?.analytics?.isActive) {
        const tagManagerIdScript = document.createElement("script");
        tagManagerIdScript.async = true;
        tagManagerIdScript.src = `https://www.googletagmanager.com/gtag/js?id=${consts.tagManagerId}`;
        document.head.appendChild(tagManagerIdScript);

        const gtagScript = document.createElement("script");
        const injectGA = `
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            window.dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "${consts.tagManagerId}");
        `;
        try {
          gtagScript.appendChild(document.createTextNode(injectGA));
          document.head.appendChild(gtagScript);
        } catch (e) {
          gtagScript.text = injectGA;
          document.head.appendChild(gtagScript);
        }
        this.trackPageview(
          this.props.location.pathname + this.props.location.search
        );
        this.props.history.listen((location, action) => {
          this.trackPageview(location.pathname + location.search);
        });
      }

      // Alternative to writing code in index.html, to be delete
      if (
        consts.fbPixel &&
        consts.fbPixelId &&
        concentList?.analytics?.isActive
      ) {
        //   const options = {
        //     autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        //     debug: false, // enable logs
        //   };
        ReactPixel.init(consts.fbPixelId);
        ReactPixel.pageView();
      }

      if (isConnected) {
        let languageId = getFromLocal("languageId");
        this.props.dispatch(TucanoActions.getListOptions(languageId));
        this.props.dispatch(
          TucanoActions.getOptionValidity(this.state.productId)
        );
        const accountDetails = await this.props.dispatch(
          TucanoActions.getAccountDetails()
        );
        if (
          accountDetails &&
          accountDetails.status &&
          accountDetails.status !== "ACTIVE"
        ) {
          let found = this.props.toasts.find((element) =>
            element.text.includes(
              t(
                "Your account has been suspended,Please contact our customer service"
              )
            )
          )
            ? true
            : false;
          // console.log('found',found)
          if (!found) {
            this.props.dispatch(
              addToast({
                text: t(
                  "Your account has been suspended,Please contact our customer service"
                ),
                className: "error",
                stayOpen: 1,
              })
            );
          }
        }
        // get account language
        languageId = accountDetails.languageId;
        // Get profile Language
        const profileActive = await this.props.dispatch(
          TucanoActions.getActiveProfile()
        );
        if (profileActive?.language) {
          languageId = profileActive.language;
        }
      }
      // Get browser language for first launch
      if (!languageId && !getFromLocal("languageId")) {
        const browserLang =
          navigator.language || navigator.userLanguage || consts.languageId;
        const filteredLanguageIdMapping = {};
        const langugeIds =
          typeof consts.enum.languageId === "string"
            ? JSON.parse(consts.enum.languageId)
            : consts.enum.languageId;
        langugeIds.map((one) => {
          filteredLanguageIdMapping[one.value] =
            consts.languageIdMapping[one.value];
        });

        if (
          new RegExp(Object.values(filteredLanguageIdMapping).join("|")).test(
            browserLang
          )
        ) {
          languageId = Object.keys(filteredLanguageIdMapping).find((key) =>
            new RegExp(filteredLanguageIdMapping[key]).test(browserLang)
          );
        } else {
          languageId = Object.keys(filteredLanguageIdMapping)[0];
        }
      } else if (!languageId && getFromLocal("languageId")) {
        languageId = getFromLocal("languageId");
      }

      // apply language
      this.props.i18n.changeLanguage(consts.languageIdMapping[languageId]);
      saveInLocal("languageId", languageId);

      this.props.dispatch(setMenuOpen(this.state.isMenuOpen));
      this.props.dispatch(TucanoActions.getPasswordPolicy());
      // let urlSearchParams = new URLSearchParams(location.search);
      // if (
      //   !["/signup", "/link", "/devices", "/confirm-account"].includes(
      //     location.pathname
      //   ) &&
      //   urlSearchParams?.toString() !== "" &&
      //   !urlSearchParams?.get("mv")
      // ) {
      //   this.props.dispatch({
      //     type: actionTypes.UPDATE_GLOBAL_LOADER,
      //     payload: true,
      //   });
      // }

      this.props
        .dispatch(TucanoActions.getReferences(languageId))
        .then(() => {
          if (this.props.isConnected) {
            this.props.dispatch(TucanoActions.getAccountInfos()).then(() => {
              // I comment this because I think it is redundant
              // this.props.dispatch(TucanoActions.getChannels(undefined, undefined, languageId));
              // I comment this for fix issue locker asset on refresh page
              // this.props.dispatch(
              //   TucanoActions.getOptionValidity(this.state.productId)
              // );
              this.props.dispatch(TucanoActions.getFavorites(languageId));
              this.props.dispatch(
                TucanoActions.getFavoritesChannels(languageId)
              );
              if (consts?.appModules?.profiles === true) {
                this.props.dispatch(TucanoActions.getActiveProfile());
                this.props.dispatch(TucanoActions.getProfile());
              } else if (!consts?.appModules?.profiles) {
                this.props
                  .dispatch(TucanoActions.getActiveProfile())
                  .then((profile) => {
                    this.props.dispatch(
                      TucanoActions.setActiveProfile(profile?.id, null)
                    );
                  });
              }
              if (
                consts?.appModules?.library === true &&
                location.pathname !== "/vod"
              ) {
                this.props.dispatch(
                  TucanoActions.getActiveAssets({
                    languageId: getFromLocal("languageId"),
                    allAssets: 0,
                    commercialModel: "TVOD",
                    contentTypeFilter: "movie+episode",
                  })
                );
              }
              this.props.dispatch(setApplicationReady(true));
            });
          } else {
            this.props.dispatch({
              type: actionTypes.UPDATE_GLOBAL_LOADER,
              payload: false,
            });
            this.props.dispatch(setApplicationReady(true));
          }
        })
        .catch((_error) => {
          this.props.dispatch({
            type: actionTypes.UPDATE_GLOBAL_LOADER,
            payload: false,
          });
          this.props.dispatch(setApplicationReady(false));
        });

      window.addEventListener(
        "resize",
        debounce(this.handleResize.bind(this), 300)
      );
      const privacyAndTermsLinks =
        [consts.routes.privacy.url.toLocaleLowerCase()].includes(
          location.pathname.toLocaleLowerCase()
        ) ||
        [consts.routes.cgu.url.toLocaleLowerCase()].includes(
          location.pathname.toLocaleLowerCase()
        );

      this.handleResize();

      // check routes without authentification
      if (
        this.props.location.pathname.includes(
          consts.routes.resetpassword.url
        ) ||
        privacyAndTermsLinks ||
        this.props.location.pathname === consts.routes.signup.url
      ) {
        this.props.dispatch(setLoginShow(false));
      } else if (
        !isConnected &&
        !consts.guestModeEnabled &&
        ![consts.routes.confirmAccount.url.toLocaleLowerCase()].includes(
          location.pathname.toLocaleLowerCase()
        ) &&
        ![consts.routes.resetpassword.url.toLocaleLowerCase()].includes(
          location.pathname.toLocaleLowerCase()
        ) &&
        !privacyAndTermsLinks
      ) {
        this.props.dispatch(
          setChannelFilter({ value: null, profile: null }, isConnected)
        );
        this.props.history.push(consts.routes.signIn.url);
      }

      if (this.props.location.pathname.includes("/search/")) {
        this.setState({ isSearchOpen: true });
      }
      // initialize Stripe script
      if (consts.paymentProvider === "stripe") {
        const script = document.createElement("script");
        script.src = `https://js.stripe.com/v3/`;
        document.head.appendChild(script);
      }
    }
    if (
      consts.appModules.gdpr &&
      consts.displayModalGDPR &&
      consts.gdprConfig &&
      (typeof consts.gdprConfig === "string"
        ? JSON.parse(consts.gdprConfig)
        : JSON.parse(JSON.stringify(consts.gdprConfig))) &&
      !concentList
    ) {
      // reset login modal to false(hide login modal)
      this.props.dispatch(setLoginShow(false));
      this.setState({
        show: true,
        modalType: MODAL_CONSENT,
      });
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.location !== this.props.location &&
      !this.props.location.search.includes("?mv=")
    ) {
      this.props.dispatch(setApplicationPreviousRoute(this.props.location));
    }

    if (
      nextProps.location !== this.props.location &&
      !this.props.location.pathname.includes("player") &&
      !this.props.location.search.includes("?mv=") &&
      (nextProps.location.pathname.includes("/asset") ||
        nextProps.location.pathname.includes("/tvshow") ||
        nextProps.location.pathname.includes("/search") ||
        nextProps.location.pathname.includes("/catchup") ||
        nextProps.location.pathname.includes("/epg"))
    ) {
      this.props.dispatch(
        setApplicationPreviousStableRoute(this.props.location)
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      "resize",
      debounce(this.handleResize.bind(this), 300)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { t, onSignupSteps, location, isConnected, dispatch } = this.props;
    // Scroll to top when page change
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0);
    }
    if (this.props.location !== prevProps.location) {
      // saving the history link
      if (!isConnected) {
        const historyLink = `${location.pathname}${location.search}`;
        if (
          ![
            consts.routes.signIn.url,
            consts.routes.logout.url,
            consts.routes.signup.url,
          ].includes(location.pathname)
        )
          dispatch(setHistoryLink(historyLink));
      }
      if (
        (!prevProps.location.pathname.includes("player") &&
          this.props.location.pathname.includes("player") &&
          !this.props.location.search.includes("?mv=")) ||
        this.props.location.pathname.includes("/profiles")
      ) {
        this.props.dispatch(
          setApplicationPreviousRoute(prevProps.location.pathname)
        );
      }
      // this is used for custom back button in case of asset and search pages
      if (
        !prevProps.location.pathname.includes("player") &&
        this.props.location.pathname.includes("player") &&
        (this.props.location.search.includes("/asset") ||
          this.props.location.search.includes("/catchup") ||
          this.props.location.search.includes("/epg") ||
          this.props.location.search.includes("/search") ||
          this.props.location.search.includes("/tvshow"))
      ) {
        this.props.dispatch(
          setApplicationPreviousStableRoute(prevProps.location.pathname)
        );
      }
      if (
        !this.props.location.pathname.includes("search") &&
        this.props.viewport.type !== "DESKTOP"
      ) {
        this.setState({ isSearchOpen: false });
      }
    }
    if (!prevProps.showDeviceModal && this.props.showDeviceModal === true) {
      disableBodyScroll();
    }

    if (prevProps.showDeviceModal && this.props.showDeviceModal === false) {
      enableBodyScroll();
    }

    if (prevProps.viewport.type !== this.props.viewport.type) {
      if (this.props.viewport.type === "DESKTOP") {
        this.setState({ isSearchOpen: true });
      } else {
        this.setState({ isSearchOpen: false });
      }
    }

    if (
      consts?.appModules?.profiles === true &&
      prevProps.isConnected !== this.props.isConnected &&
      !this.props.profileToken &&
      !(this.state.show && this.state.modalType === MODAL_PROFILE_SELECTION)
    ) {
      this.props.dispatch(TucanoActions.getProfile()).then(() => {
        this.props.dispatch(TucanoActions.getActiveProfile()).then(() => {
          if (this.props.location.pathname === consts.routes.signup.url) {
            const { activeProfile } = this.props;
            this.props.dispatch(
              TucanoActions.setActiveProfile(activeProfile.getId(), null)
            );
          } else {
            this.showModal(MODAL_PROFILE_SELECTION);
          }
        });
      });
    } else if (
      !consts?.appModules?.profiles &&
      prevProps.isConnected !== this.props.isConnected &&
      !this.props.profileToken
    ) {
      this.props.dispatch(TucanoActions.getActiveProfile()).then((profile) => {
        this.props.dispatch(TucanoActions.setActiveProfile(profile?.id, null));
        let redirectTo = consts.routes.home.url;
        if (this.props.historyLink) {
          redirectTo = this.props.historyLink;
          dispatch(resetHistoryLink());
        }
        if (!onSignupSteps) window.location = redirectTo;
      });
    }
    if (this.props.location.search === "?login=1") {
      this.props.dispatch(setLoginShow(true));
      this.props.history.replace(this.props.location.pathname);
      // window.location.reload();
    }
    if (this.props.location.search === "?login=0") {
      this.handleHideLogin();
      // console.log(this.state.prevLocation);
      //this.props.history.push(this.props.location.pathname);
      // in cas of user close the login popup stay in the origin page
      // this.props.history.push(this.state.prevLocation);
    }

    if (prevState.isSearchOpen === true && this.state.isSearchOpen === false) {
      this.props.dispatch(TucanoActions.resetSearchResults());
    }
    if (
      prevProps &&
      this.props &&
      !prevProps.optionValidity &&
      this.props.optionValidity
    ) {
      for (var i = 0; i < this.props.toasts.length; i++) {
        if (
          this.props.toasts[i].text.includes(
            t("A payment is pending or has failed")
          )
        ) {
          this.props.dispatch(removeToast(this.props.toasts[i].id));
        }
      }
      // Wait componentDidMount for all other page

      setTimeout(() => {
        if (this.props.optionValidity && this.props.optionValidity.length > 0) {
          for (let j = 0; j < this.props.optionValidity.length; j++) {
            if (
              this.props.optionValidity[j].statusId === 30 ||
              this.props.optionValidity[j].statusId === 60
            ) {
              let found = this.props.toasts.find((element) =>
                element.text.includes(t("A payment is pending or has failed"))
              )
                ? true
                : false;
              if (!found) {
                this.addToast(
                  t("A payment is pending or has failed,Click here"),
                  consts.routes.subscription.url,
                  "primary"
                );
                break;
              }
            }
          }
        }
      }, 3000);
    }
  }

  handleResize() {
    this.props.dispatch(
      setApplicationViewport({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    );
  }

  async handleShowLogin() {
    const { isConnected } = this.props;
    const { isMenuOpen } = this.state;
    if (!isConnected) {
      await this.props.dispatch(setLoginShow(true));
      await this.setState({
        prevLocation: this.props.history.location.pathname,
      });
      // We close the menu to prevent a bug with CSSTransition
      if (isMenuOpen) {
        setTimeout(() => {
          this.toggleMenu();
        }, 500);
      }
    }
  }

  handleHideLogin() {
    this.props.dispatch(setLoginShow(false));
  }

  toggleSearch() {
    this.setState((prevState) => ({
      isSearchOpen: !prevState.isSearchOpen,
    }));
  }

  toggleMenu() {
    const { isMenuOpen } = this.state;
    this.setState({ isMenuOpen: !isMenuOpen });
    this.props.dispatch(setMenuOpen(!isMenuOpen));
  }

  deleteDevice(index) {
    const currentDeviceId = getFromLocal(consts.storageKeys.device);
    const { t } = this.props;
    let deviceId = this.props.devices[index].getUniqueDeviceId();
    this.props
      .dispatch(TucanoActions.casRemoveDevice(deviceId))
      .then((_result) => {
        const { dataOnCasRemoveDevice } = this.props;
        if (dataOnCasRemoveDevice && dataOnCasRemoveDevice.code) {
          for (var i = 0; i < this.props.toasts.length; i++) {
            if (
              this.props.toasts[i].text.includes(
                t(
                  this.getErrorAPI(
                    "casRemoveDevice",
                    dataOnCasRemoveDevice.code
                  )
                )
              )
            ) {
              this.props.dispatch(removeToast(this.props.toasts[i].id));
            }
          }
          // Wait componentDidMount for all other page
          let found = this.props.toasts.find((element) =>
            element.text.includes(
              t(this.getErrorAPI("casRemoveDevice", dataOnCasRemoveDevice.code))
            )
          )
            ? true
            : false;
          if (!found) {
            this.addToast(
              t(
                this.getErrorAPI("casRemoveDevice", dataOnCasRemoveDevice.code)
              ),
              null,
              "error",
              6000
            );
          }
        } else {
          if (dataOnCasRemoveDevice.newAuthToken) {
            if (currentDeviceId === deviceId) {
              this.props.history.push(consts.routes.logout.url);
            }
            deviceId = getFromLocal(consts.storageKeys.device);
            this.props
              .dispatch(TucanoActions.addDevice(deviceId))
              .then(async () => {
                // Authencate the Device
                let device = { id: deviceId, ...detect() };
                await this.props.dispatch(TucanoActions.authDevice(device));
                this.props.dispatch(setDeviceLimitError(false));
              });
            clearAllBodyScrollLocks();
          }
        }
      });
  }

  addToast(text, url, className, duration) {
    this.props.dispatch(
      addToast({
        text: text,
        url: url,
        className: className,
        duration: duration,
      })
    );
  }

  checkKidProfile() {
    const { activeProfile } = this.props;
    if (activeProfile && activeProfile.getKidProfile()) {
      this.showModal(MODAL_PARENTAL_CONTROL);
    } else {
      this.showProfileSelect();
    }
  }

  showProfileSelect() {
    this.setState({ isMenuOpen: false });
    this.showModal(MODAL_PROFILE_SELECTION);
  }

  selectProfile(item) {
    const { dispatch, historyLink } = this.props;
    dispatch(TucanoActions.setActiveProfile(item.getId(), null)).then(() => {
      let redirectTo = consts.routes.home.url;
      if (historyLink) {
        redirectTo = historyLink;
        dispatch(resetHistoryLink());
      }
      window.location = redirectTo?.includes("confirm") ? "/" : redirectTo;
    });
  }

  hideModalProfileSelection() {
    const { activeProfile, profileToken, historyLink, dispatch } = this.props;
    if (!profileToken && activeProfile) {
      dispatch(
        TucanoActions.setActiveProfile(activeProfile.getId(), null)
      ).then(() => {
        saveInLocal("languageId", activeProfile.language);
        let redirectTo = consts.routes.home.url;
        if (historyLink) {
          redirectTo = historyLink;
          dispatch(resetHistoryLink());
        }
        window.location = redirectTo;
      });
    } else {
      this.hideModal();
    }
  }

  subscriptionModal = (asset) => {
    this.setState({ assetSubscription: asset });
    this.showModal(MODAL_NEW_SUBSCRIPTION);
  };

  showModal(modalType) {
    this.setState({ show: true, modalType: modalType });
  }

  hideModal(modalType) {
    this.setState({
      show: false,
      modalType: modalType,
      showDeviceModal: false,
    });

    this.props.dispatch(setDeviceLimitError(false));
    clearAllBodyScrollLocks();

    if (this.props.location.pathname.includes("player")) {
      if (this.props.history.length > 2) {
        this.props.history.goBack();
      } else {
        this.props.history.push("/");
      }
      // this.props.dispatch(setDeviceLimitError(false));
      // clearAllBodyScrollLocks();
    }
  }

  reload() {
    window.location.reload();
  }

  toggleSignInPopup() {
    const { history, location } = this.props;
    this.props.dispatch(setLoginShow(false));
    const protectedRoutes = [
      consts.routes.profile.url,
      consts.routes.account.url,
      consts.routes.favorites.url,
      consts.routes.history.url,
      consts.routes.library.url,
      consts.routes.subscription.url,
      consts.routes.devices.url,
      consts.routes.profiles.url,
    ];
    const result = matchPath(location.pathname, protectedRoutes);
    if (result) {
      this.props.history.push("/");
    } else {
      // Get the path name
      let path = history.location.pathname.split("/")[1];
      // This is the only case when we have to redirect back, else we just close the Popup
      if (path === "player" || path === "playerlive") {
        this.props.history.goBack();
      }
    }
  }

  toggleErrorPopup() {
    this.props.dispatch(setErrorShow(!this.props.showApiError));
  }

  showLoginPopup(e) {
    e.preventDefault();
    this.props.dispatch(setLoginShow(true));
  }

  onManageConcentList = (isManage) => {
    this.setState({ manageConsentListOpned: isManage });
  };

  render() {
    const { isMenuOpen, show, modalType, ssoLoading, manageConsentListOpned } =
      this.state;
    const {
      isConnected,
      isApplicationReady,
      isProfileFormExist,
      viewport,
      devices,
      deviceCounter,
      showDeviceModal,
      isDevicesLoading,
      userInfo,
      activeProfile,
      showLogin,
      showApiError,
      t,
      serverError,
      history,
    } = this.props;
    const direction = getFromLocal("layoutDirection");
    const finalContentClassName = classnames({
      [style.contentMenuOpenedLeft]:
        isMenuOpen &&
        direction === "ltr" &&
        history.location.pathname !== "/sign-in",
      [style.contentMenuOpenedRight]:
        isMenuOpen &&
        direction === "rtl" &&
        history.location.pathname !== "/sign-in",
      [style.contentMenuClosedLeft]:
        !isMenuOpen &&
        direction === "ltr" &&
        history.location.pathname !== "/sign-in",
      [style.contentMenuClosedRight]:
        !isMenuOpen &&
        direction === "rtl" &&
        history.location.pathname !== "/sign-in",
      [style.contentMenuHidden]: !shouldDisplayMenu(),
    });
    const finalMenuClassName = classnames(style.appMenu, {
      [style.menuOpened]: isMenuOpen,
      [style.menuClosed]: !isMenuOpen,
    });
    const finalWidthClassName = classnames({
      [style.widthMenuOpened]: isMenuOpen,
      [style.widthMenuClosed]: !isMenuOpen,
      [style.widthMenuHidden]: !shouldDisplayMenu(),
    });

    const isLocalIsp = isConnectedFromLocalISP();
    if (isLocalIsp) {
      document
        .querySelector("head .favicon")
        .setAttribute("href", "/assets/local_favicon.ico");
    }

    const isPagePlayerLive =
      this.props.location.pathname.includes("/playerlive/");
    if (serverError) {
      return <ErrorMessage />;
    }
    let leftContentConcentModal = { logo: true };
    if (manageConsentListOpned) {
      leftContentConcentModal = {
        backBtn: this.onManageConcentList.bind(this, false),
      };
    }
    return (
      <Fragment>
        {(this.props.loading || ssoLoading) && <Loader centered={true} />}
        {!this.props.loading && !ssoLoading && (
          <Fragment>
            <Toasts
              rootClassName={classnames(
                finalContentClassName,
                finalWidthClassName
              )}
            />
            {isApplicationReady === undefined && !isPagePlayerLive && (
              <Loader centered={true} />
            )}
            {isApplicationReady === false && <ErrorMessage />}
            {isApplicationReady === true && (
              <Fragment>
                {show && modalType === MODAL_CONSENT && (
                  <Modal
                    size={"lg"}
                    rootClassName={classnames(
                      style.modal,
                      style.constantModal,
                      style.consentModal
                    )}
                    leftContent={leftContentConcentModal}
                    rightContent={{ langDropDown: true }}
                    overlay={true}
                  >
                    <PageConsent
                      handleActions={this.hideModal.bind(this)}
                      history={history}
                      onManageConcentList={this.onManageConcentList.bind(this)}
                      manageConsentListOpned={manageConsentListOpned}
                    />
                  </Modal>
                )}
                {isConnected === false && showLogin === true && (
                  <Modal
                    leftContent={{ title: t("Log in to continue") }}
                    rightContent={{
                      canDismiss: this.toggleSignInPopup.bind(this),
                    }}
                    rootClassName={style.modal}
                    overlay={true}
                  >
                    <PageLogin isPopup={true} t={t} />
                  </Modal>
                )}

                {showApiError && (
                  <Modal
                    leftContent={{ title: t("Error") }}
                    rightContent={{
                      canDismiss: this.toggleErrorPopup.bind(this),
                    }}
                    rootClassName={style.modal}
                    overlay={true}
                  >
                    <ErrorMessage
                      message={t(showApiError.errorMessage)}
                      rootClassName="popupError"
                      isPopup={true}
                    />
                  </Modal>
                )}

                {showDeviceModal && (
                  <Modal
                    size={"lg"}
                    leftContent={{
                      title: t("You have reached your maximum device limit"),
                    }}
                    rightContent={{ canDismiss: this.hideModal.bind(this) }}
                    overlay={true}
                  >
                    {isDevicesLoading && <Loader />}
                    {!isDevicesLoading && (
                      <DevicesManager
                        devices={devices}
                        deviceCounter={deviceCounter}
                        showMessage={true}
                        onDeleteClick={this.deleteDevice.bind(this)}
                      />
                    )}
                  </Modal>
                )}
                {show && modalType === MODAL_PROFILE_SELECTION && (
                  <Modal
                    modalType={MODAL_PROFILE_SELECTION}
                    rootClassName={classnames(style.modal, style.profileModal, {
                      [style.biggerModal]: isProfileFormExist,
                    })}
                    size={"md"}
                    typeModal={MODAL_PROFILE_SELECTION}
                    leftContent={{ title: t("Select a profile") }}
                    rightContent={{
                      canDismiss: this.hideModalProfileSelection.bind(this),
                    }}
                    overlay={true}
                  >
                    <Profile
                      selectProfile={this.selectProfile.bind(this)}
                      rootClassName={style.profileSelection}
                      shouldUpdateLanguage={true}
                    />
                  </Modal>
                )}
                {show && modalType === MODAL_PARENTAL_CONTROL && (
                  <ModalValidateParentalCode
                    onCloseClick={this.hideModal.bind(this)}
                    onCancelClick={this.hideModal.bind(this)}
                    onConfirmClick={this.showProfileSelect.bind(this)}
                  />
                )}
                {shouldDisplayMenu() && (
                  <Menu
                    userInfo={userInfo}
                    profileInfo={activeProfile}
                    isOpened={isMenuOpen}
                    isConnected={isConnected}
                    rootClassName={finalMenuClassName}
                    onToggleClick={this.toggleMenu.bind(this)}
                    loginHandler={this.handleShowLogin.bind(this)}
                    viewport={viewport}
                    onSwitchProfile={this.checkKidProfile.bind(this)}
                    showLoginPopup={this.showLoginPopup.bind(this)}
                  />
                )}
                {consts.appModules.search === true &&
                  shouldDisplaySearchIcon() && (
                    <Search
                      className={classnames(
                        style.search,
                        style[`search-${direction.toUpperCase()}`]
                      )}
                      isMenuOpen={isMenuOpen}
                      isVisible={this.state.isSearchOpen}
                      onClick={this.toggleSearch.bind(this)}
                      viewport={viewport}
                    />
                  )}
                {isMenuOpen && (
                  <div
                    className={style.menuOverlay}
                    onClick={this.toggleMenu.bind(this)}
                  />
                )}
                <ErrorHandler pathname={this.props.location.pathname}>
                  <div
                    id="container"
                    className={classnames(
                      style.appContent,
                      finalContentClassName
                    )}
                  >
                    {renderRoutes(this.props.route.routes, {
                      isMenuOpen: isMenuOpen,
                      isConnected: isConnected,
                      subscriptionModal: this.subscriptionModal,
                    })}
                    {!this.props.location.pathname.includes("player") &&
                      isLocalIsp && <Footer />}
                  </div>
                </ErrorHandler>
                {show && modalType === MODAL_NEW_SUBSCRIPTION && (
                  <ModalSubscription
                    item={this.state.assetSubscription}
                    onCloseClick={this.hideModal.bind(
                      this,
                      MODAL_NEW_SUBSCRIPTION
                    )}
                    onConfirmClick={this.reload.bind(this)}
                  />
                )}
                <Transaction parentProps={this.props} />
              </Fragment>
            )}
          </Fragment>
        )}
        <form className="form-payment" />
      </Fragment>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      loading: state.account.globalLoader.loading,
      isConnected: state.session.customerAuthToken !== undefined,
      isDiscoverMode: state.session.discoverMode,
      toasts: state.ui.toasts,
      showLogin: state.ui.loginShown,
      showApiError: state.ui.showApiError,
      customerAuthToken: state.session.customerAuthToken,
      isProfileFormExist: state.ui.isProfileFormExist,
      profileToken: state.session.profileToken !== undefined,
      showDeviceModal: state.session.deviceLimitError,
      isApplicationReady: state.ui.isApplicationReady,
      previousRoute: state.ui.previousRoute,
      previousStableRoute: state.ui.previousStableRoute,
      viewport: state.ui.viewport,
      devices: TucanoSelectors.getDevices(state),
      deviceCounter: TucanoSelectors.getDeviceCounter(state),
      isDevicesLoading: false, // state.account.devices.loading,
      userInfo: TucanoSelectors.getUserInfo(state),
      accountDetail: state.account.user.data,
      optionValidity: TucanoSelectors.getOptionValidity(state),
      dataOnCasRemoveDevice: state.account.casRemoveDevice.data,
      activeProfile: TucanoSelectors.getActiveProfile(state),
      availableOffers: state.subscription.availableOffers.data,
      onSignupSteps: state.account.onSignupSteps,
      serverError: state.ui.serverError,
      historyLink: state.ui.historyLink,
    };
  }),
  withTranslation()
)(withRouter(App));

App.propTypes = {
  isConnected: PropTypes.bool,
};

App.defaultProps = {
  isConnected: false,
};
